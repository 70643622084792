import React, { useState } from "react";

import AuthLayout from "../AuthLayout";
import { LoginContainer, LoginFormContainer, LoginTitle } from "../styles";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";
import {
      EmailVerify,
      ForgetPasswordSection,
      VerificationCode,
} from "../../../components/auth";

const PasswordReset = () => {
      const [status, setStatus] = useState(false);
      const [verifyStatus, setVerifyStatus] = useState(false);
      const [email, setEmail] = useState("");
      const [slug, setSlug] = useState(false);

      const login = localStorage.getItem("isLoggedIn");
      if (login) {
            return <Navigate to="/" />;
      }
      return (
            <>
                  <Helmet>
                        <title>Reset Your Password</title>
                  </Helmet>
                  <AuthLayout
                        mobileDisplay="none"
                        desktopDisplay="block"
                        classes={10}
                  >
                        <LoginFormContainer mobilePadding="20vh" className="w-100">
                              <Container>
                                    <LoginTitle
                                          marginBottom="1.5rem"
                                          fontSize="24px"
                                    >
                                          Reset Your Password
                                    </LoginTitle>
                                    {status ? (
                                          verifyStatus ? (
                                                <ForgetPasswordSection
                                                      slug={slug}
                                                />
                                          ) : (
                                                <EmailVerify
                                                      setVerifyStatus={
                                                            setVerifyStatus
                                                      }
                                                      email={email}
                                                      slug={slug}
                                                      setSlug={setSlug}
                                                />
                                          )
                                    ) : (
                                          <VerificationCode
                                                email={email}
                                                setEmail={setEmail}
                                                setStatus={setStatus}
                                                setSlug={setSlug}
                                          />
                                    )}
                              </Container>
                        </LoginFormContainer>
                  </AuthLayout>
            </>
      );
};

export default PasswordReset;
