import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import {
      BannerContainer,
      LoginAdditional,
      LoginContainer,
      LoginDetail,
      LoginDetailContent,
} from "../styles";
import { Navigate } from "react-router-dom";
import InfoModal from "../InfoModal";
import BannerSlider from "../BannerSlider";
import { PreloaderContainer } from "../../../components/Common/Preloader/styles";

const AuthLayout = ({ children, classes, mobileDisplay, desktopDisplay }) => {
      const login = localStorage.getItem("isLoggedIn");

      if (login) {
            return <Navigate to="/" />;
      }

      return (
            <div className="position-relative">
                  <PreloaderContainer position="absolute">
                        <InfoModal />
                        <LoginContainer height="100%">
                              <Container className="h-100" fluid>
                                    <Row className="h-100">
                                          <BannerContainer
                                                mobileDisplay={mobileDisplay}
                                                lg={6}
                                                className="border-end"
                                          >
                                                <LoginAdditional className="h-100">
                                                      <Row className="justify-content-center h-100">
                                                            <LoginDetailContent
                                                                  lg={12}
                                                                  className="p-0"
                                                            >
                                                                  <BannerSlider />
                                                            </LoginDetailContent>
                                                      </Row>
                                                </LoginAdditional>
                                          </BannerContainer>
                                          <LoginDetail
                                                desktopDisplay={desktopDisplay}
                                                lg={6}
                                          >
                                                <LoginDetailContent
                                                      lg={classes || 7}
                                                >
                                                      {children}
                                                </LoginDetailContent>
                                          </LoginDetail>
                                    </Row>
                              </Container>
                        </LoginContainer>
                  </PreloaderContainer>
            </div>
      );
};

export default AuthLayout;
