import React, { useEffect, useState } from "react";
import {
      FlightAirline,
      FlightAirlineTime,
      FlightAirlineTitle,
      FlightDetailAirline,
      FlightDetailCategory,
      FlightDetailContainer,
      FlightDetailInfo,
} from "../../../../Dashboard/FlightDetails/style";
import { Spinner } from "react-bootstrap";
import { convertAmount, convertFloat } from "../../../../../hooks/CurrentData";
import { tripJackApi } from "../../../../../api";

const PriceRuleDetail = ({ fareId, leavingFrom, destination }) => {
      const [fareDetails, setFareDetails] = useState(false);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);

            await tripJackApi
                  .post(
                        `/fms/v1/farerule`,
                        {
                              id: fareId?.id,
                              flowType: "SEARCH",
                        },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        setFareDetails(response.data?.fareRule);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [fareId?.id]);

      // const farePrice
      const splitDetails = (policyString) => {
            const policyList = policyString.split("__nls__");
            return policyList;
      };

      return (
            <>
                  <FlightDetailContainer>
                        <FlightDetailCategory>{`${leavingFrom} - ${destination}`}</FlightDetailCategory>
                        <FlightDetailInfo>
                              {!loading ? (
                                    <>
                                          {fareDetails ? (
                                                <>
                                                      <FlightDetailAirline>
                                                            <FlightAirlineTime width="25%">
                                                                  <div>
                                                                        Type
                                                                  </div>
                                                                  <span>
                                                                        Date
                                                                        Change
                                                                  </span>
                                                            </FlightAirlineTime>
                                                            <FlightAirline width="20%">
                                                                  <FlightAirlineTitle>
                                                                        <div>
                                                                              Amount
                                                                        </div>
                                                                        <span>
                                                                              ₹{" "}
                                                                              {convertAmount(
                                                                                    convertFloat(
                                                                                          fareDetails[
                                                                                                `${leavingFrom}-${destination}`
                                                                                          ]
                                                                                                ?.fr
                                                                                                ?.DATECHANGE
                                                                                                ?.DEFAULT
                                                                                                ?.amount
                                                                                    )
                                                                              ) ||
                                                                                    convertAmount(
                                                                                          convertFloat(
                                                                                                fareDetails[
                                                                                                      `${leavingFrom}-${destination}`
                                                                                                ]
                                                                                                      ?.fr
                                                                                                      ?.DATECHANGE
                                                                                                      ?.BEFORE_DEPARTURE
                                                                                                      ?.amount
                                                                                          )
                                                                                    )}
                                                                        </span>
                                                                  </FlightAirlineTitle>
                                                            </FlightAirline>
                                                            <FlightAirlineTime width="55%">
                                                                  <div>
                                                                        Policy
                                                                  </div>
                                                                  <span>
                                                                        {fareDetails[
                                                                              `${leavingFrom}-${destination}`
                                                                        ]?.fr
                                                                              ?.DATECHANGE
                                                                              ?.DEFAULT
                                                                              ?.policyInfo
                                                                              ? splitDetails(
                                                                                      fareDetails[
                                                                                            `${leavingFrom}-${destination}`
                                                                                      ]
                                                                                            ?.fr
                                                                                            ?.DATECHANGE
                                                                                            ?.DEFAULT
                                                                                            ?.policyInfo
                                                                                )?.map(
                                                                                      (
                                                                                            policy,
                                                                                            index
                                                                                      ) =>
                                                                                            policy && (
                                                                                                  <li
                                                                                                        key={
                                                                                                              index
                                                                                                        }
                                                                                                  >
                                                                                                        {
                                                                                                              policy
                                                                                                        }
                                                                                                  </li>
                                                                                            )
                                                                                )
                                                                              : fareDetails[
                                                                                      `${leavingFrom}-${destination}`
                                                                                ]
                                                                                      ?.fr
                                                                                      ?.DATECHANGE
                                                                                      ?.BEFORE_DEPARTURE
                                                                                      ?.policyInfo}
                                                                  </span>
                                                            </FlightAirlineTime>
                                                      </FlightDetailAirline>
                                                      <FlightDetailAirline marginTop="1.5rem">
                                                            <FlightAirlineTime width="25%">
                                                                  <span>
                                                                        CANCELLATION
                                                                  </span>
                                                            </FlightAirlineTime>
                                                            <FlightAirline width="20%">
                                                                  <FlightAirlineTitle>
                                                                        <span>
                                                                              ₹{" "}
                                                                              {convertAmount(
                                                                                    convertFloat(
                                                                                          fareDetails[
                                                                                                `${leavingFrom}-${destination}`
                                                                                          ]
                                                                                                ?.fr
                                                                                                ?.CANCELLATION
                                                                                                ?.DEFAULT
                                                                                                ?.amount
                                                                                    )
                                                                              ) ||
                                                                                    convertAmount(
                                                                                          convertFloat(
                                                                                                fareDetails[
                                                                                                      `${leavingFrom}-${destination}`
                                                                                                ]
                                                                                                      ?.fr
                                                                                                      ?.CANCELLATION
                                                                                                      ?.BEFORE_DEPARTURE
                                                                                                      ?.amount
                                                                                          )
                                                                                    )}
                                                                        </span>
                                                                  </FlightAirlineTitle>
                                                            </FlightAirline>
                                                            <FlightAirlineTime width="55%">
                                                                  <span>
                                                                        {fareDetails[
                                                                              `${leavingFrom}-${destination}`
                                                                        ]?.fr
                                                                              ?.CANCELLATION
                                                                              ?.DEFAULT
                                                                              ?.policyInfo
                                                                              ? splitDetails(
                                                                                      fareDetails[
                                                                                            `${leavingFrom}-${destination}`
                                                                                      ]
                                                                                            ?.fr
                                                                                            ?.CANCELLATION
                                                                                            ?.DEFAULT
                                                                                            ?.policyInfo
                                                                                )?.map(
                                                                                      (
                                                                                            policy,
                                                                                            index
                                                                                      ) =>
                                                                                            policy && (
                                                                                                  <li
                                                                                                        key={
                                                                                                              index
                                                                                                        }
                                                                                                  >
                                                                                                        {
                                                                                                              policy
                                                                                                        }
                                                                                                  </li>
                                                                                            )
                                                                                )
                                                                              : fareDetails[
                                                                                      `${leavingFrom}-${destination}`
                                                                                ]
                                                                                      ?.fr
                                                                                      ?.CANCELLATION
                                                                                      ?.BEFORE_DEPARTURE
                                                                                      ?.policyInfo}
                                                                  </span>
                                                            </FlightAirlineTime>
                                                      </FlightDetailAirline>
                                                </>
                                          ) : (
                                                <>
                                                      <FlightDetailAirline>
                                                            <FlightAirlineTime width="100%">
                                                                  <span className="text-danger">
                                                                        Keys
                                                                        Passed
                                                                        in the
                                                                        request
                                                                        is
                                                                        already
                                                                        expired.
                                                                        Please
                                                                        pass
                                                                        valid
                                                                        keys
                                                                  </span>
                                                            </FlightAirlineTime>
                                                      </FlightDetailAirline>
                                                </>
                                          )}
                                    </>
                              ) : (
                                    <>
                                          <div className="w-100 text-center mb-3">
                                                <Spinner />
                                          </div>
                                    </>
                              )}
                        </FlightDetailInfo>
                  </FlightDetailContainer>
            </>
      );
};

export default PriceRuleDetail;
