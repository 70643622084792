import React, { useState } from "react";
import { CloseButton, Col, ModalBody, ModalHeader } from "react-bootstrap";
import { FlightModal } from "../FlightConfirmModal/style";
import CheckFare from "./CheckFare";
import CancelForm from "./CancelForm";
import NormalCancelForm from "./CheckFare/NormalCancelForm";

function convertTripInfosToTrips(tripInfos) {
      const trips = [];

      tripInfos.forEach((tripInfo, index) => {
            const segments = tripInfo.sI;
            if (segments.length === 0) return;

            const firstSegment = segments[0];
            const lastSegment = segments[segments.length - 1];

            const travellers = firstSegment.bI.tI.map((traveler) => ({
                  ti: traveler.ti,
                  pt: traveler.pt,
                  fn: traveler.fN,
                  ln: traveler.lN,
                  checked: false,
            }));

            trips.push({
                  src: firstSegment.da.code,
                  dest: lastSegment.aa.code,
                  index: index,
                  checked: index === 0 ? true : false,
                  departureDate: firstSegment.dt.split("T")[0], // Extract date
                  travellers,
            });
      });

      return { trips };
}

const CancelRequestForm = ({
      requestType,
      bookingId,
      mainModalClose,
      setShowMessage,
      agentOrder,
      travellerInfos,
      tripInfos,
}) => {
      const [agreeShow, setAgreeShow] = useState(false);

      const [cancelStatus, setCancelStatus] = useState([
            {
                  title: "Quote for Normal",
                  checked: false,
            },
            {
                  title: "Quote for Manual",
                  checked: false,
            },
            {
                  title: "Quote for Specific",
                  checked: false,
            },
      ]);

      const countTypes = travellerInfos.reduce(
            (acc, traveller) => {
                  acc[traveller.pt] = (acc[traveller.pt] || 0) + 1;
                  return acc;
            },
            { ADULT: 0, CHILD: 0, INFANT: 0 }
      );

      const handleClose = () => setAgreeShow(false);
      const handleShow = () => setAgreeShow(true);

      const handleCancelOption = (index) => {
            setCancelStatus((prevItem) => {
                  return prevItem?.map((item, i) => {
                        return {
                              ...item,
                              checked: i === index,
                        };
                  });
            });
      };

      const currentCancelOption = cancelStatus?.find((item) => item?.checked);

      return (
            <>
                  <Col lg={12}>
                        <CheckFare
                              options={cancelStatus}
                              handleCancelOption={handleCancelOption}
                        />
                        {currentCancelOption?.title ===
                              "Quote for Specific" && (
                              <CancelForm
                                    bookingId={bookingId}
                                    cancelStatus={cancelStatus}
                                    mainModalClose={mainModalClose}
                                    setShowMessage={setShowMessage}
                                    requestType={requestType}
                                    handleShow={handleShow}
                                    travellerInfos={convertTripInfosToTrips(
                                          tripInfos
                                    )}
                              />
                        )}
                        {currentCancelOption?.title === "Quote for Normal" && (
                              <NormalCancelForm
                                    agentOrder={agentOrder}
                                    requestType={requestType}
                                    countTypes={countTypes}
                                    currentCancelOption={currentCancelOption}
                              />
                        )}
                  </Col>
                  <FlightModal
                        backdrop="static"
                        show={agreeShow}
                        top="18px"
                        centered
                        backgroundColor={(props) => props.theme.primary}
                  >
                        <ModalHeader>
                              Cancellation Policy Disclaimer
                              <CloseButton onClick={handleClose} />
                        </ModalHeader>

                        <ModalBody>
                              <ol>
                                    <li>
                                          Cancellation charges, as per the
                                          airline or hotel’s policy, will apply
                                          and may vary.
                                    </li>
                                    <li>
                                          Refunds, if applicable, will be
                                          processed as per the supplier’s terms
                                          and conditions.
                                    </li>
                                    <li>
                                          Aircasa is not liable for any delays
                                          in refunds caused by the airline,
                                          hotel, or payment gateway.
                                    </li>
                                    <li>
                                          Additional service charges by Aircasa
                                          may apply for processing the
                                          cancellation.
                                    </li>
                                    <li>
                                          This request does not guarantee an
                                          immediate refund or confirmation of
                                          cancellation.
                                    </li>
                              </ol>
                        </ModalBody>
                  </FlightModal>
            </>
      );
};

export default CancelRequestForm;
