import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import {
      RescheduleDepartureContent,
      RescheduleDepartureItem,
      RescheduleDepartureList,
} from "./style";
import {
      Col,
      Form,
      FormControl,
      FormGroup,
      FormLabel,
      Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CommonButton from "../../../components/Common/CommonButton";
import ReactSelect from "react-select";
import { Checkbox, FormControlLabel } from "@mui/material";

const options = [
      {
            value: "Normal cancellation",
            label: "Normal cancellation",
      },
      {
            value: "⁠Full Refund issued by Airlines",
            label: "⁠Full Refund issued by Airlines",
      },
      {
            value: "Already Cancelled With airlines",
            label: "Already Cancelled With airlines",
      },
      {
            value: "Flight Cancelled By the airlines",
            label: "Flight Cancelled By the airlines",
      },
];

const CancelForm = ({
      requestType,
      handleShow,
      setShowMessage,
      mainModalClose,
      bookingId,
      travellerInfos,
      cancelStatus,
}) => {
      const [loading, setLoading] = useState(false);
      const [reason, setReason] = useState("");
      const [travellers, setTravellers] = useState(travellerInfos?.trips);
      const [remarks, setRemarks] = useState("");
      const [additionalReasons, setAdditionalReasons] = useState("");

      const token = JSON.parse(localStorage.getItem("token"));

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  request_type: requestType?.value,
                  destinations: cancelStatus,
                  reason: reason?.value,
                  travellers: travellers,
                  additional_reason: additionalReasons,
                  remarks: remarks,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/${bookingId}/cancel`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              // toast.success(response.data.message);
                              setShowMessage(true);
                              mainModalClose();
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.request_type) {
                                    toast.error(
                                          response.data.message.request_type
                                    );
                              }
                              if (response.data.message.reason) {
                                    toast.error(response.data.message.reason);
                              }
                              if (response.data.message.remarks) {
                                    toast.error(response.data.message.remarks);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      const handleClick = (index) => {
            setTravellers((prevTrips) => {
                  return prevTrips.map((trip, i) => {
                        // Set the clicked trip to true and all others to false
                        return {
                              ...trip,
                              checked: i === index, // If it's the clicked index, set to true, else false
                        };
                  });
            });
      };

      const currentTraveler = travellers?.find((item) => item?.checked);

      const handleTraveller = (tripIndex, travellerIndex) => {
            setTravellers((prevTrips) => {
                  const updatedTrips = [...prevTrips];
                  updatedTrips[tripIndex].travellers = updatedTrips[
                        tripIndex
                  ].travellers.map((traveller, i) => {
                        if (i === travellerIndex) {
                              // Toggle the checked status of the clicked traveller
                              return {
                                    ...traveller,
                                    checked: !traveller.checked,
                              };
                        }
                        return traveller;
                  });
                  return updatedTrips;
            });
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <RescheduleDepartureContent>
                              <RescheduleDepartureList className="mb-3">
                                    {travellers?.map((item, index) => (
                                          <RescheduleDepartureItem
                                                className={`${
                                                      item?.checked
                                                            ? "active"
                                                            : ""
                                                }`}
                                                key={index}
                                                onClick={() =>
                                                      handleClick(index)
                                                }
                                          >
                                                {`${item?.src}-${item?.dest}`}
                                          </RescheduleDepartureItem>
                                    ))}
                              </RescheduleDepartureList>
                              <Row className="g-3">
                                    <Col lg={12}>
                                          <FormLabel>
                                                Cancellation Reason{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <ReactSelect
                                                value={reason}
                                                options={options}
                                                required={true}
                                                onChange={setReason}
                                          />
                                    </Col>
                                    <Col lg={8}>
                                          <FormLabel className="mb-0">
                                                Passengers
                                          </FormLabel>
                                          <FormGroup>
                                                {currentTraveler?.travellers?.map(
                                                      (item, index) => (
                                                            <FormControlLabel
                                                                  checked={
                                                                        item?.checked
                                                                  }
                                                                  control={
                                                                        <Checkbox />
                                                                  }
                                                                  label={`${item?.ti} ${item?.fn} ${item?.ln} (${item.pt})`}
                                                                  onClick={() =>
                                                                        handleTraveller(
                                                                              currentTraveler?.index,
                                                                              index
                                                                        )
                                                                  }
                                                            />
                                                      )
                                                )}
                                          </FormGroup>
                                    </Col>
                                    <Col lg={12}>
                                          <FormLabel>
                                                Additional Reason
                                          </FormLabel>
                                          <FormControl
                                                rows={4}
                                                as="textarea"
                                                onChange={(event) =>
                                                      setAdditionalReasons(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={12}>
                                          By submitting this cancellation
                                          request, you acknowledge and agree to
                                          the{" "}
                                          <Link onClick={handleShow}>
                                                policy
                                          </Link>
                                          .
                                    </Col>
                                    <Col lg={12}>
                                          <CommonButton
                                                loading={loading}
                                                button={true}
                                                type="submit"
                                                title="Submit"
                                                classes="justify-content-center
                                                mt-3"
                                                padding="0.6rem 1.5rem"
                                          />
                                    </Col>
                              </Row>
                        </RescheduleDepartureContent>
                  </Form>
            </>
      );
};

export default CancelForm;
