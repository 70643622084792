import React, { useState } from "react";
import $ from "jquery";
import { publicApi } from "../../../api";
import toast from "react-hot-toast";
import { Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";
import { LoginInput } from "../../form/FormInput/style";
import { MailIcon } from "../../../icons";
import CommonButton from "../../Common/CommonButton";

const VerificationCode = ({ email, setEmail, setStatus, setSlug }) => {
      const [loading, setLoading] = useState(false);

      async function handleTrackForm(event) {
            event.preventDefault();
            setLoading(true);
            $("button[type=submit]").prop("disabled", true);
            if (email === "") {
                  setTimeout(() => {
                        setLoading(false);
                        $("button[type=submit]").prop("disabled", false);
                        toast.error("Please enter your email address.");
                  }, 3000);
            } else {
                  const data = {
                        email: email,
                  };
                  await publicApi
                        .post(`/auth/password/reset`, data)
                        .then((response) => {
                              setTimeout(() => {
                                    setLoading(false);
                                    $("button[type=submit]").prop(
                                          "disabled",
                                          false
                                    );
                                    if (response.data.result === "success") {
                                          setStatus(true);
                                          setSlug(response.data.slug);
                                    }

                                    if (response.data.result === "error") {
                                          if (response.data.message.email) {
                                                toast.error(
                                                      response.data.message
                                                            .email
                                                );
                                          }
                                    }
                              }, 3000);
                        })
                        .catch((error) => {
                              setTimeout(() => {
                                    setLoading(false);
                                    $("button[type=submit]").prop(
                                          "disabled",
                                          false
                                    );
                                    toast.error(error.message);
                              }, 3000);
                        });
            }
      }

      return (
            <>
                  <Form onSubmit={handleTrackForm}>
                        <Row className="g-3">
                              <Col lg={8}>
                                    <FormLabel>Your Email Address</FormLabel>
                                    <LoginInput className="ps-2 ">
                                          <MailIcon />
                                          <FormControl
                                                type="email"
                                                placeholder="Email"
                                                name="email"
                                                onChange={(event) =>
                                                      setEmail(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </LoginInput>
                              </Col>
                              <Col lg={12} className="text-center">
                                    <CommonButton
                                          button={true}
                                          type="submit"
                                          title="Continue"
                                    />
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default VerificationCode;
