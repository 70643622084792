import React from "react";
import DashboardLayout from "../layouts";
import {
      DashboardCard,
      DashboardCardBody,
      DashboardCardHeader,
      DashboardCardTitle,
} from "../layouts/style";
import { MenuIcon } from "../layouts/menus/MenuItem/style";
import { ChangePasswordIcon, MyBookingIcon } from "../../icons";
import {
      MenuOptionIcon,
      MenuOptionItem,
      MenuOptionName,
      MenuOptions,
} from "../bank_deposits/style";
import { Helmet } from "react-helmet";

const MyBooking = () => {
      return (
            <>
                  <Helmet>
                        <title>My Bookings</title>
                  </Helmet>
                  <DashboardLayout title="My Bookings">
                        <DashboardCard className="m-0">
                              <DashboardCardHeader>
                                    <DashboardCardTitle>
                                          <MenuIcon>
                                                <MyBookingIcon />
                                          </MenuIcon>
                                          My Bookings
                                    </DashboardCardTitle>
                              </DashboardCardHeader>
                              <DashboardCardBody padding="10px">
                                    <MenuOptions>
                                          <MenuOptionItem to="/view-flight-booking">
                                                <MenuOptionIcon
                                                      fontSize="52px"
                                                      color="#224082eb"
                                                >
                                                      <MyBookingIcon />
                                                </MenuOptionIcon>
                                                <MenuOptionName>
                                                      Flight Booking
                                                </MenuOptionName>
                                          </MenuOptionItem>
                                          <MenuOptionItem to="/view-hotel-booking">
                                                <MenuOptionIcon
                                                      color="#224082eb"
                                                      fontSize="52px"
                                                >
                                                      <ChangePasswordIcon />
                                                </MenuOptionIcon>
                                                <MenuOptionName>
                                                      Hotel Booking
                                                </MenuOptionName>
                                          </MenuOptionItem>
                                    </MenuOptions>
                              </DashboardCardBody>
                        </DashboardCard>
                  </DashboardLayout>
            </>
      );
};

export default MyBooking;
