import styled from "styled-components";

export const SendButton = styled.button`
      border: 0;
      text-decoration: none;
      padding: 0;
      font-size: 14px;
      color: ${props => props.theme.primary};
      margin-top: -3px;

      .spinner-border {
            width: 1rem !important;
            height: 1rem !important;
            margin-left: 10px;
      }

      &:hover {
            color: rgba(232, 107, 25, 80%);
      }
`;
