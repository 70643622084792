import React, { useContext, useEffect, useState } from "react";
import { CheckoutContainer } from "../checkout/styles";
import { DashboardContainer } from "../dashboard/styles";
import { Col, Row, Spinner } from "react-bootstrap";
import PriceSummaryBox from "../../components/Frontend/checkout/PriceSummaryBox";
import { useParams } from "react-router-dom";
import {
      CheckoutReview,
      CountdownTimer,
} from "../../components/Frontend/flight_reviews";
import { optionInfo } from "../../data/dashboard";
import FlightReviewOptions from "../../components/Frontend/flight_reviews/FlightReviewOptions";
import { FlightReviewContent } from "./style";
import {
      checkCommission,
      totalAmountAfterCommission,
} from "../../data/commission";
import UserContext from "../../context/UserContext";
import { tripJackApi } from "../../api";

const FlightReview = () => {
      const userCtx = useContext(UserContext);
      const commission = userCtx?.profile?.commission;

      const { optionId } = useParams();
      const { reviewId } = useParams();

      const splitArray = reviewId.split(",");

      const [showData, setShowData] = useState(false);
      const [statusShow, setStatusShow] = useState(false);
      const [loading, setLoading] = useState(false);
      const [priceInfo, setPriceInfo] = useState([]);
      const [bookingId, setBookingId] = useState("");
      const [tripInfos, setTripInfos] = useState([]);
      const [searchQuery, setSearchQuery] = useState([]);
      const [options, setOptions] = useState(optionInfo);
      const [currentOption, setCurrentOption] = useState({
            id: optionInfo.find((item) => item.id === optionId).id,
            index: optionInfo.find((item) => item.id === optionId).index,
      });
      const [conditions, setConditions] = useState([]);
      const [modalStatus, setModalStatus] = useState(false);
      const [addedAmount, setAddedAmount] = useState(0);

      const [serviceList, setServiceList] = useState([]);
      const [destinationDetails, setDestinationDetails] = useState([]);

      // Default Amount
      const [totalAmount, setTotalAmount] = useState(0);
      const [totalNetAmount, setTotalNetAmount] = useState(0);
      const [commissionAmt, setCommissionAmt] = useState(0);
      const [seriesFareStatus, setSeriesFareStatus] = useState(false);

      const calculateTotalAmount = ({ domesticStatus, item, paxInfo }) => {
            let totalAmount = 0;
            let newAddedAmount = 0;

            item?.map((segment) => {
                  const totalPriceList = segment?.totalPriceList[0];
                  if (totalPriceList?.fd?.ADULT) {
                        const beforeCommissionAmount =
                              totalPriceList?.fd?.ADULT?.fC?.TF;
                        const commissionAmount = totalAmountAfterCommission({
                              airlineCode: segment?.sI[0]?.fD?.aI?.code,
                              domesticStatus,
                              commission,
                              fareDetail: totalPriceList,
                              totalFare: beforeCommissionAmount,
                        });
                        newAddedAmount +=
                              commissionAmount - beforeCommissionAmount;
                        totalAmount += commissionAmount * paxInfo?.ADULT;
                  }
                  if (totalPriceList?.fd?.CHILD) {
                        const beforeCommissionAmount =
                              totalPriceList?.fd?.CHILD?.fC?.TF;
                        const commissionAmount = totalAmountAfterCommission({
                              airlineCode: segment?.sI[0]?.fD?.aI?.code,
                              domesticStatus,
                              commission,
                              fareDetail: totalPriceList,
                              totalFare: beforeCommissionAmount,
                        });
                        newAddedAmount +=
                              commissionAmount - beforeCommissionAmount;

                        totalAmount += commissionAmount * paxInfo?.CHILD;
                  }
                  if (totalPriceList?.fd?.INFANT) {
                        totalAmount +=
                              totalPriceList?.fd?.INFANT?.fC?.TF *
                              paxInfo?.INFANT;
                  }
            });

            setAddedAmount(newAddedAmount);
            return totalAmount;
      };

      // commission handle
      const calculateTotalNetAmount = ({ domesticStatus, item, paxInfo }) => {
            let newAmount = 0;
            let newCommissionAmount = 0;

            item?.map((segment) => {
                  const totalPriceList = segment?.totalPriceList[0];

                  if (totalPriceList?.fd?.ADULT) {
                        const beforeCommissionAmount =
                              totalPriceList?.fd?.ADULT?.fC?.TF;
                        const commissionAmount = checkCommission({
                              airlineCode: segment?.sI[0]?.fD?.aI?.code,
                              fareDetail: totalPriceList,
                              commission: commission,
                              domesticStatus: domesticStatus,
                              totalFare: totalPriceList?.fd?.ADULT?.fC?.TF,
                              netFare: totalPriceList?.fd?.ADULT?.fC?.NF,
                        });
                        newCommissionAmount +=
                              commissionAmount - beforeCommissionAmount;

                        newAmount += commissionAmount * paxInfo?.ADULT;
                  }
                  if (totalPriceList?.fd?.CHILD) {
                        const beforeCommissionAmount =
                              totalPriceList?.fd?.CHILD?.fC?.TF;
                        const commissionAmount = checkCommission({
                              airlineCode: segment?.sI[0]?.fD?.aI?.code,
                              fareDetail: totalPriceList,
                              commission: commission,
                              domesticStatus: domesticStatus,
                              totalFare: totalPriceList?.fd?.CHILD?.fC?.TF,
                              netFare: totalPriceList?.fd?.CHILD?.fC?.NF,
                        });
                        newCommissionAmount +=
                              commissionAmount - beforeCommissionAmount;

                        newAmount += commissionAmount * paxInfo?.CHILD;
                  }
                  if (totalPriceList?.fd?.INFANT) {
                        newAmount +=
                              totalPriceList?.fd?.INFANT?.fC?.TF *
                              paxInfo?.INFANT;
                  }
            });

            setCommissionAmt(newCommissionAmount);
            return newAmount;
      };

      const loadData = async () => {
            setLoading(true);
            let data;
            if (splitArray?.length > 1) {
                  data = {
                        priceIds: [splitArray[0], splitArray[1]],
                  };
            } else {
                  data = {
                        priceIds: [splitArray[0]],
                  };
            }

            if (userCtx?.profile?.flight_fee) {
                  await tripJackApi
                        .post(`/fms/v1/review`, data)
                        .then((response) => {
                              if (response.data.status.success === true) {
                                    setShowData(true);
                                    setStatusShow(true);
                                    localStorage.setItem(
                                          "reviewTime",
                                          response.data.conditions.st
                                    );

                                    setTripInfos(response.data.tripInfos);
                                    console.log(response.data);
                                    console.log("Hello");
                                    setSearchQuery(response.data.searchQuery);
                                    setBookingId(response.data.bookingId);
                                    setConditions(response.data.conditions);

                                    setPriceInfo(
                                          response.data.totalPriceInfo
                                                .totalFareDetail
                                    );

                                    const paxInfo =
                                          response.data.searchQuery.paxInfo;
                                    const domesticStatus =
                                          response?.data?.searchQuery
                                                ?.isDomestic;
                                    const travelInfos = response.data.tripInfos;

                                    // Commission
                                    const seriesStatus =
                                          travelInfos[0]?.totalPriceList[0]
                                                ?.fareIdentifier ===
                                          "OFFER_FARE_WITH_PNR"
                                                ? true
                                                : false;
                                    setSeriesFareStatus(seriesStatus);
                                    const totalNet = calculateTotalNetAmount({
                                          domesticStatus,
                                          item: travelInfos,
                                          paxInfo,
                                    });
                                    setTotalNetAmount(
                                          totalNet +
                                                userCtx?.profile?.flight_fee
                                    );
                                    // After commission amount
                                    const afterCommissionAmount =
                                          calculateTotalAmount({
                                                domesticStatus,
                                                item: travelInfos,
                                                paxInfo,
                                          });
                                    // console.log(afterCommissionAmount)

                                    setTotalAmount(
                                          afterCommissionAmount +
                                                userCtx?.profile?.flight_fee
                                    );
                              }
                              setLoading(false);
                        })
                        .catch((error) => {
                              setStatusShow(true);
                              if (
                                    error?.response?.data?.status?.success ===
                                    false
                              ) {
                                    setShowData(false);
                                    setModalStatus(true);
                              }
                              setLoading(false);
                        });
            }
      };

      useEffect(() => {
            const updateOptions = options.map((item, i) => {
                  if (i <= currentOption.index) {
                        return { ...item, status: true };
                  }
                  return { ...item, status: false };
            });
            setOptions(updateOptions);
            loadData();
            if (optionId === "travellers") {
                  localStorage.removeItem("paymentStatus");
            }
      }, [userCtx]);

      useEffect(() => {
            const storedServices = localStorage.getItem("services");
            if (storedServices) {
                  setServiceList(JSON.parse(storedServices));
            }
      }, []);

      return (
            <>
                  <CheckoutContainer>
                        <DashboardContainer
                              padding="130px"
                              flexDirection="column"
                        >
                              <CheckoutReview reviewId={reviewId} />
                              <Row className="w-100">
                                    {!loading ? (
                                          <>
                                                <Col lg={8}>
                                                      {!loading && showData ? (
                                                            <FlightReviewOptions
                                                                  seriesFareStatus={
                                                                        seriesFareStatus
                                                                  }
                                                                  addedAmount={
                                                                        addedAmount
                                                                  }
                                                                  bookingId={
                                                                        bookingId
                                                                  }
                                                                  conditions={
                                                                        conditions
                                                                  }
                                                                  currentOption={
                                                                        currentOption
                                                                  }
                                                                  options={
                                                                        options
                                                                  }
                                                                  tripInfos={
                                                                        tripInfos
                                                                  }
                                                                  setOptions={
                                                                        setOptions
                                                                  }
                                                                  setCurrentOption={
                                                                        setCurrentOption
                                                                  }
                                                                  optionId={
                                                                        optionId
                                                                  }
                                                                  reviewId={
                                                                        reviewId
                                                                  }
                                                                  searchQuery={
                                                                        searchQuery
                                                                  }
                                                                  setTotalAmount={
                                                                        setTotalAmount
                                                                  }
                                                                  totalAmount={
                                                                        totalAmount
                                                                  }
                                                                  setTotalNetAmount={
                                                                        setTotalNetAmount
                                                                  }
                                                                  totalNetAmount={
                                                                        totalNetAmount
                                                                  }
                                                                  setServiceList={
                                                                        setServiceList
                                                                  }
                                                                  serviceList={
                                                                        serviceList
                                                                  }
                                                                  destinationDetails={
                                                                        destinationDetails
                                                                  }
                                                                  setDestinationDetails={
                                                                        setDestinationDetails
                                                                  }
                                                            />
                                                      ) : null}
                                                </Col>
                                                <Col lg={4}>
                                                      <FlightReviewContent>
                                                            {!loading &&
                                                            statusShow ? (
                                                                  <CountdownTimer
                                                                        conditions={
                                                                              conditions
                                                                        }
                                                                        modalRequired={
                                                                              true
                                                                        }
                                                                        modalStatus={
                                                                              modalStatus
                                                                        }
                                                                        setModalStatus={
                                                                              setModalStatus
                                                                        }
                                                                  />
                                                            ) : null}
                                                            <PriceSummaryBox
                                                                  priceInfo={
                                                                        priceInfo
                                                                  }
                                                                  tripInfos={
                                                                        tripInfos
                                                                  }
                                                                  totalAmount={
                                                                        totalAmount
                                                                  }
                                                                  setTotalAmount={
                                                                        setTotalAmount
                                                                  }
                                                                  totalNetAmount={
                                                                        totalNetAmount
                                                                  }
                                                                  setTotalNetAmount={
                                                                        setTotalNetAmount
                                                                  }
                                                                  searchQuery={
                                                                        searchQuery
                                                                  }
                                                                  serviceList={
                                                                        serviceList
                                                                  }
                                                                  currentOption={
                                                                        currentOption
                                                                  }
                                                            />
                                                            <div
                                                                  className="support mb-2"
                                                                  style={{
                                                                        visibility:
                                                                              "hidden",
                                                                  }}
                                                            >
                                                                  Flight support
                                                                  : +91
                                                                  8766601888
                                                            </div>
                                                      </FlightReviewContent>
                                                </Col>
                                          </>
                                    ) : (
                                          <>
                                                <Col
                                                      lg={12}
                                                      className="text-center mt-5 pt-5"
                                                >
                                                      <Spinner />
                                                </Col>
                                          </>
                                    )}
                              </Row>
                        </DashboardContainer>
                  </CheckoutContainer>
            </>
      );
};

export default FlightReview;
