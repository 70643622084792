import React, { useState } from "react";
import { BookingFilter } from "../FlightTable/style";
import {
      Button,
      Col,
      Form,
      FormControl,
      FormLabel,
      Row,
      Spinner,
} from "react-bootstrap";
import Select from "react-select";
import { FilterButton } from "../../bank_deposits/style";
import axios from "axios";

const searchOptions = [
      {
            label: "Confirmation No",
            value: "Confirmation No",
      },
      {
            label: "Booking Code",
            value: "Booking Code",
      },
];

const statusOptions = [
      {
            label: "Confirmed",
            value: "Confirmed",
      },
      {
            label: "Cancelled",
            value: "Cancelled",
      },
      {
            label: "Hold",
            value: "Hold",
      },
      {
            label: "Pending",
            value: "Pending",
      },
];

const convertDate = (dateStr) => {
      const date = new Date(dateStr);

      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
};

const HotelTableFilter = ({ setBookings }) => {
      const [loadingData, setLoadingData] = useState(false);
      const [searchOption, setSearchOption] = useState("");
      const [statusOption, setStatusOption] = useState("");
      const [startingDate, setStartingDate] = useState(new Date());
      const [endingDate, setEndingDate] = useState("");
      const [searchValue, setSearchValue] = useState("");
      const token = JSON.parse(localStorage.getItem("token"));
      const handleForm = async (event) => {
            event.preventDefault();
            setLoadingData(true);
            await axios
                  .get(
                        `${
                              process.env.REACT_APP_SECRET_KEY
                        }/api/agent/flight/bookings?order_type=HOTEL&startingDate=${convertDate(
                              startingDate
                        )}&endingDate=${
                              endingDate ? convertDate(endingDate) : ""
                        }&searchOption=${
                              searchOption ? searchOption?.value : ""
                        }&statusOption=${
                              statusOption ? statusOption?.value : ""
                        }&searchValue=${searchValue}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBookings(response.data.bookings);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoadingData(false);
      };

      const handleClear = () => {
            setSearchOption("");
            setStatusOption("");
            setStartingDate(new Date());
            setEndingDate("");
            setSearchValue("");
      };
      return (
            <BookingFilter>
                  <Form onSubmit={handleForm}>
                        <Row className="mb-4 g-3 pb-4 border-bottom">
                              <Col lg={4}>
                                    <FormLabel>Chose One</FormLabel>
                                    <Row className="g-0">
                                          <Col lg={5} className="col-5">
                                                <Select
                                                      options={searchOptions}
                                                      value={searchOption}
                                                      onChange={setSearchOption}
                                                      placeholder="Confirmation No."
                                                />
                                          </Col>
                                          <Col lg={7} className="col-7">
                                                <FormControl
                                                      type="text"
                                                      onChange={(event) =>
                                                            setSearchValue(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                      value={searchValue}
                                                />
                                          </Col>
                                    </Row>
                              </Col>
                              <Col lg={3}>
                                    <FormLabel>Status</FormLabel>
                                    <Select
                                          options={statusOptions}
                                          placeholder="Booking Status"
                                          onChange={setStatusOption}
                                          value={statusOption}
                                    />
                              </Col>
                              <Col lg={2}>
                                    <FormLabel>Booking Date Start</FormLabel>
                                    <FormControl
                                          type="date"
                                          onChange={(event) =>
                                                setStartingDate(
                                                      event.target.value
                                                )
                                          }
                                          value={convertDate(startingDate)}
                                    />
                              </Col>
                              <Col lg={2}>
                                    <FormLabel>Booking Date End</FormLabel>
                                    <FormControl
                                          type="date"
                                          onChange={(event) =>
                                                setEndingDate(
                                                      event.target.value
                                                )
                                          }
                                          value={
                                                endingDate
                                                      ? convertDate(endingDate)
                                                      : ""
                                          }
                                    />
                              </Col>
                              <Col lg={3}>
                                    <FilterButton
                                          type="submit"
                                    >
                                          {loadingData ? <Spinner /> : "Search"}
                                    </FilterButton>
                                    <Button
                                          onClick={handleClear}
                                          type="button"
                                          className="btn btn-link btn-danger mx-3 text-decoration-none"
                                    >
                                          Clear All
                                    </Button>
                              </Col>
                        </Row>
                  </Form>
            </BookingFilter>
      );
};

export default HotelTableFilter;
