import React, { useEffect, useState } from "react";
import {
      DashboardContainer,
      DashboardContent,
      DashboardFlightRanges,
      DashboardFlightWrapper,
      DashboardMenu,
      DashboardMenuContent,
      FilterContainer,
} from "../dashboard/styles";
import { arrivals, departures, stops } from "../../data/dashboard";
import { useLocation } from "react-router-dom";
import axios from "axios";
import FlightLoader from "../../components/Common/FlightLoader";
import FlightModal from "./FlightModal";
import {
      FlightHeader,
      FlightHeaderContent,
      FlightHeaderInfo,
      FlightModalInfo,
} from "./style";
import {
      OnewayFlight,
      RoundtripFlight,
} from "../../components/Frontend/search";
import toast from "react-hot-toast";
import OnewayFilter from "../../components/Frontend/flight/OnewayFilter";
import { RoundtripFilter } from "../../components/Frontend/flight";
import {
      RoundtripFilterItem,
      RoundtripFilterContainer,
} from "../../components/Frontend/flight/RoundtripFilter/style";
import { convertDate } from "../../hooks/CurrentData";
import ComboFlight from "../../components/Frontend/search/ComboFlight";
import ComboFilter from "../../components/Frontend/flight/ComboFilter";
import { tripJackApi } from "../../api";

const FlightSearch = () => {
      const location = useLocation();
      const searchParams = new URLSearchParams(location.search);

      const [searchDetail, setSearchDetail] = useState({
            leavingFrom: searchParams.get("leavingFrom"),
            destination: searchParams.get("destination"),
            departDate: searchParams.get("depart"),
            adult: searchParams.get("adult"),
            infants: searchParams.get("infants"),
            children: searchParams.get("children"),
            returnDate: searchParams.get("returnDate"),
            returnStatus: searchParams.get("returnStatus"),
            pft: searchParams.get("pft"),
            cabinClass: searchParams.get("className"),
            nonStop: searchParams.get("nonStop"),
      });

      const [loading, setLoading] = useState(false);

      const [flights, setFlights] = useState([]);
      const [returnAirlines, setReturnAirlines] = useState([]);
      const [filteredFlights, setFilteredFlights] = useState([]);

      // Combo
      const [comboFlights, setComboFlights] = useState([]);
      const [filteredComboFlights, setFilteredComboFlights] = useState([]);
      const [totalComboFlights, setTotalComboFlights] = useState(0);

      const token = JSON.parse(localStorage.getItem("token"));
      const [netFare, setNetFare] = useState(false);
      const [fullFare, setFullFare] = useState(false);
      const [searchDetails, setSearchDetails] = useState([]);

      const [totalFlights, setTotalFlights] = useState(0);
      const [returnFlights, setReturnFlights] = useState([]);
      const [returnFilterFlights, setReturnFilterFlights] = useState([]);

      let searchData;
      let pftDetail;

      if (
            searchDetail?.pft === "STUDENT" ||
            searchDetail?.pft === "SENIOR_CITIZEN"
      ) {
            if (
                  searchDetail?.children === "0" &&
                  searchDetail?.infants === "0"
            ) {
                  pftDetail = {
                        pft: searchDetail?.pft,
                  };
            } else {
                  toast.error(
                        `You cannot select ${searchDetail?.pft} with child/infant.`
                  );
            }
      } else {
            pftDetail = {
                  isDirectFlight: true,
                  isConnectingFlight: true,
            };
      }
      if (searchDetail?.returnStatus === "true") {
            searchData = {
                  searchQuery: {
                        cabinClass: searchDetail?.cabinClass,
                        paxInfo: {
                              ADULT: searchDetail?.adult,
                              CHILD: searchDetail?.children,
                              INFANT: searchDetail?.infants,
                        },
                        routeInfos: [
                              {
                                    fromCityOrAirport: {
                                          code: searchDetail?.leavingFrom,
                                    },
                                    toCityOrAirport: {
                                          code: searchDetail?.destination,
                                    },
                                    travelDate: searchDetail?.departDate,
                              },
                              {
                                    fromCityOrAirport: {
                                          code: searchDetail?.destination,
                                    },
                                    toCityOrAirport: {
                                          code: searchDetail?.leavingFrom,
                                    },
                                    travelDate: searchDetail?.returnDate,
                              },
                        ],
                        searchModifiers: pftDetail,
                  },
            };
      } else {
            searchData = {
                  searchQuery: {
                        cabinClass: searchDetail?.cabinClass,
                        paxInfo: {
                              ADULT: searchDetail?.adult,
                              CHILD: searchDetail?.children,
                              INFANT: searchDetail?.infants,
                        },
                        routeInfos: [
                              {
                                    fromCityOrAirport: {
                                          code: searchDetail?.leavingFrom,
                                    },
                                    toCityOrAirport: {
                                          code: searchDetail?.destination,
                                    },
                                    travelDate: searchDetail?.departDate,
                              },
                        ],
                        searchModifiers: pftDetail,
                  },
            };
      }

      const mergeFlightsBySegment = (flightList) => {
            const tempMergedFlights = []; // Temporary array to hold merged flights
            const mergedFlightsFlags = new Array(flightList.length).fill(false); // Track if a flight has been merged

            for (let i = 0; i < flightList.length; i++) {
                  const flight = flightList[i];

                  if (!flight || mergedFlightsFlags[i]) continue; // Skip if the flight is null or already merged

                  // For flights with multiple segments, add directly to the merged list
                  if (flight?.sI?.length > 1) {
                        tempMergedFlights.push({
                              ...flight,
                              totalPriceList: [...flight.totalPriceList],
                        });
                  } else {
                        let mergedFlight = {
                              ...flight,
                              totalPriceList: [...flight.totalPriceList],
                        };

                        // Check for segment match with subsequent flights
                        for (let j = i + 1; j < flightList.length; j++) {
                              const compareFlight = flightList[j];
                              if (!compareFlight || mergedFlightsFlags[j])
                                    continue; // Skip if already merged or null

                              // Check if any segment matches
                              const isSegmentMatch = flight.sI.some(
                                    (segmentA) =>
                                          compareFlight.sI.some(
                                                (segmentB) =>
                                                      segmentA.fD.aI.code ===
                                                            segmentB.fD.aI
                                                                  .code && // Airline code matches
                                                      segmentA.fD.fN ===
                                                            segmentB.fD.fN && // Duration matches
                                                      segmentA.dt ===
                                                            segmentB.dt && // Departure time matches
                                                      segmentA.at ===
                                                            segmentB.at
                                          )
                              );

                              // If segment matches, merge the price list and mark as merged
                              if (isSegmentMatch) {
                                    mergedFlight.totalPriceList = [
                                          ...mergedFlight.totalPriceList,
                                          ...compareFlight.totalPriceList,
                                    ];

                                    // Mark the compareFlight as merged
                                    mergedFlightsFlags[j] = true; // Prevent adding this flight again
                              }
                        }

                        // Add the merged flight to tempMergedFlights
                        tempMergedFlights.push(mergedFlight);
                  }
            }

            return tempMergedFlights;
      };

      const mergeFlightsBySegmentPrice = (flightList) => {
            const tempMergedFlights = []; // Temporary array to hold merged flights
            const mergedFlightsFlags = new Array(flightList.length).fill(false); // Track if a flight has been merged

            for (let i = 0; i < flightList.length; i++) {
                  const flight = flightList[i];

                  if (!flight || mergedFlightsFlags[i]) continue; // Skip if the flight is null or already merged

                  let mergedFlight = {
                        ...flight,
                        totalPriceList: [...flight.totalPriceList],
                  };

                  // Check for segment match with subsequent flights
                  for (let j = i + 1; j < flightList.length; j++) {
                        const compareFlight = flightList[j];
                        if (!compareFlight || mergedFlightsFlags[j]) continue; // Skip if already merged or null

                        // Check if any segment matches
                        const isSegmentMatch = flight.sI.some((segmentA) =>
                              compareFlight.sI.some(
                                    (segmentB) =>
                                          segmentA.fD.aI.code ===
                                                segmentB.fD.aI.code && // Airline code matches
                                          segmentA.fD.fN === segmentB.fD.fN && // Flight number matches
                                          segmentA.dt === segmentB.dt && // Departure time matches
                                          segmentA.at === segmentB.at // Arrival time matches
                              )
                        );

                        // Check if `TF` matches
                        const isTFMatch = mergedFlight.totalPriceList.some(
                              (priceA) =>
                                    compareFlight.totalPriceList.some(
                                          (priceB) =>
                                                priceA.fd.ADULT.fC.TF ===
                                                priceB.fd.ADULT.fC.TF
                                    )
                        );

                        if (isSegmentMatch && isTFMatch) {
                              // Merge price lists if no `TF` match
                              mergedFlight.totalPriceList = [
                                    ...mergedFlight.totalPriceList,
                                    ...compareFlight.totalPriceList.filter(
                                          (priceB) =>
                                                !mergedFlight.totalPriceList.some(
                                                      (priceA) =>
                                                            priceA.fd.ADULT.fC
                                                                  .TF ===
                                                            priceB.fd.ADULT.fC
                                                                  .TF
                                                )
                                    ),
                              ];

                              // Mark the compareFlight as merged
                              mergedFlightsFlags[j] = true; // Prevent adding this flight again
                        }
                  }

                  // Add the merged flight to tempMergedFlights
                  tempMergedFlights.push(mergedFlight);
            }

            return tempMergedFlights;
      };

      const handleData = async () => {
            setLoading(true);

            await tripJackApi
                  .post(`/fms/v1/air-search-all`, searchData)
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.status?.success === true) {
                                    if (
                                          response.data.searchResult?.tripInfos
                                                ?.COMBO
                                    ) {
                                          const flightData =
                                                response.data.searchResult
                                                      ?.tripInfos?.COMBO;
                                          const economyFlights =
                                                flightData &&
                                                flightData
                                                      .filter((item) =>
                                                            item.totalPriceList.some(
                                                                  (price) =>
                                                                        price.fd
                                                                              .ADULT
                                                                              .cc ===
                                                                        searchDetail?.cabinClass
                                                            )
                                                      )
                                                      ?.sort((a, b) => {
                                                            const priceA =
                                                                  Math.min(
                                                                        ...a.totalPriceList
                                                                              .filter(
                                                                                    (
                                                                                          price
                                                                                    ) =>
                                                                                          price
                                                                                                .fd
                                                                                                .ADULT
                                                                                                .cc ===
                                                                                          searchDetail?.cabinClass
                                                                              )
                                                                              .map(
                                                                                    (
                                                                                          price
                                                                                    ) =>
                                                                                          price
                                                                                                ?.fd
                                                                                                ?.ADULT
                                                                                                ?.fC
                                                                                                ?.TF ||
                                                                                          Infinity
                                                                              )
                                                                  );
                                                            const priceB =
                                                                  Math.min(
                                                                        ...b.totalPriceList
                                                                              .filter(
                                                                                    (
                                                                                          price
                                                                                    ) =>
                                                                                          price
                                                                                                .fd
                                                                                                .ADULT
                                                                                                .cc ===
                                                                                          searchDetail?.cabinClass
                                                                              )
                                                                              .map(
                                                                                    (
                                                                                          price
                                                                                    ) =>
                                                                                          price
                                                                                                ?.fd
                                                                                                ?.ADULT
                                                                                                ?.fC
                                                                                                ?.TF ||
                                                                                          Infinity
                                                                              )
                                                                  );

                                                            return (
                                                                  priceA -
                                                                  priceB
                                                            );
                                                      });
                                          const flightDetails =
                                                mergeFlightsBySegmentPrice(
                                                      economyFlights
                                                );
                                          setComboFlights(flightDetails);
                                          setFilteredComboFlights(
                                                flightDetails
                                          );
                                          setTotalComboFlights(
                                                flightDetails
                                                      ? flightDetails.length
                                                      : 0
                                          );
                                    }
                                    if (
                                          response.data.searchResult?.tripInfos
                                                ?.ONWARD
                                    ) {
                                          const flightData =
                                                response.data.searchResult
                                                      ?.tripInfos?.ONWARD;
                                          const flightDetails =
                                                mergeFlightsBySegment(
                                                      flightData
                                                );

                                          const economyFlights =
                                                flightDetails &&
                                                flightDetails
                                                      .filter((item) =>
                                                            item.totalPriceList.some(
                                                                  (price) =>
                                                                        price.fd
                                                                              .ADULT
                                                                              .cc ===
                                                                        searchDetail?.cabinClass
                                                            )
                                                      )
                                                      ?.sort((a, b) => {
                                                            const priceA =
                                                                  Math.min(
                                                                        ...a.totalPriceList
                                                                              .filter(
                                                                                    (
                                                                                          price
                                                                                    ) =>
                                                                                          price
                                                                                                .fd
                                                                                                .ADULT
                                                                                                .cc ===
                                                                                          searchDetail?.cabinClass
                                                                              )
                                                                              .map(
                                                                                    (
                                                                                          price
                                                                                    ) =>
                                                                                          price
                                                                                                ?.fd
                                                                                                ?.ADULT
                                                                                                ?.fC
                                                                                                ?.TF ||
                                                                                          Infinity
                                                                              )
                                                                  );
                                                            const priceB =
                                                                  Math.min(
                                                                        ...b.totalPriceList
                                                                              .filter(
                                                                                    (
                                                                                          price
                                                                                    ) =>
                                                                                          price
                                                                                                .fd
                                                                                                .ADULT
                                                                                                .cc ===
                                                                                          searchDetail?.cabinClass
                                                                              )
                                                                              .map(
                                                                                    (
                                                                                          price
                                                                                    ) =>
                                                                                          price
                                                                                                ?.fd
                                                                                                ?.ADULT
                                                                                                ?.fC
                                                                                                ?.TF ||
                                                                                          Infinity
                                                                              )
                                                                  );

                                                            return (
                                                                  priceA -
                                                                  priceB
                                                            );
                                                      });
                                          setFlights(economyFlights);
                                          setFilteredFlights(economyFlights);
                                          setTotalFlights(
                                                economyFlights
                                                      ? economyFlights.length
                                                      : 0
                                          );
                                          if (
                                                searchDetail?.returnStatus ===
                                                "true"
                                          ) {
                                                const flightReturnData =
                                                      response.data.searchResult
                                                            ?.tripInfos?.RETURN;
                                                const returnFlightDetails =
                                                      mergeFlightsBySegment(
                                                            flightReturnData
                                                      );
                                                const economyReturnFlights =
                                                      returnFlightDetails
                                                            .filter((item) =>
                                                                  item.totalPriceList.some(
                                                                        (
                                                                              price
                                                                        ) =>
                                                                              price
                                                                                    .fd
                                                                                    .ADULT
                                                                                    .cc ===
                                                                              searchDetail?.cabinClass
                                                                  )
                                                            )
                                                            ?.sort((a, b) => {
                                                                  const priceA =
                                                                        Math.min(
                                                                              ...a.totalPriceList
                                                                                    .filter(
                                                                                          (
                                                                                                price
                                                                                          ) =>
                                                                                                price
                                                                                                      .fd
                                                                                                      .ADULT
                                                                                                      .cc ===
                                                                                                searchDetail?.cabinClass
                                                                                    )
                                                                                    .map(
                                                                                          (
                                                                                                price
                                                                                          ) =>
                                                                                                price
                                                                                                      ?.fd
                                                                                                      ?.ADULT
                                                                                                      ?.fC
                                                                                                      ?.TF ||
                                                                                                Infinity
                                                                                    )
                                                                        );
                                                                  const priceB =
                                                                        Math.min(
                                                                              ...b.totalPriceList
                                                                                    .filter(
                                                                                          (
                                                                                                price
                                                                                          ) =>
                                                                                                price
                                                                                                      .fd
                                                                                                      .ADULT
                                                                                                      .cc ===
                                                                                                searchDetail?.cabinClass
                                                                                    )
                                                                                    .map(
                                                                                          (
                                                                                                price
                                                                                          ) =>
                                                                                                price
                                                                                                      ?.fd
                                                                                                      ?.ADULT
                                                                                                      ?.fC
                                                                                                      ?.TF ||
                                                                                                Infinity
                                                                                    )
                                                                        );

                                                                  return (
                                                                        priceA -
                                                                        priceB
                                                                  );
                                                            });

                                                setReturnFlights(
                                                      economyReturnFlights
                                                );
                                                setReturnFilterFlights(
                                                      economyReturnFlights
                                                );
                                                setTotalFlights(
                                                      totalFlights +
                                                            economyReturnFlights?.length
                                                );
                                          }
                                    }
                              }
                        }, 1000);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight_destinations`,
                        {
                              leaving_from_code: searchDetail?.leavingFrom,
                              destination_code: searchDetail?.destination,
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setSearchDetails(response.data.details);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            handleData();
            localStorage.removeItem("contactDetails");
            localStorage.removeItem("gstDetails");
            localStorage.removeItem("travellers");
            localStorage.removeItem("services");
            localStorage.removeItem("totalAmount");
            localStorage.removeItem("totalNetAmount");
            localStorage.removeItem("reviewId");
            localStorage.removeItem("reviewTime");
      }, []);

      const totalTraveller = () => {
            return (
                  parseInt(searchDetail?.adult) +
                  parseInt(searchDetail?.children) +
                  parseInt(searchDetail?.infants)
            );
      };

      return (
            <>
                  {!loading ? (
                        <DashboardContainer
                              padding="130px"
                              flexDirection="column"
                        >
                              <FlightModal
                                    searchDetail={searchDetail}
                                    searchDetails={searchDetails}
                              />
                              <div className="d-flex" style={{ gap: "20px" }}>
                                    <DashboardMenu className="">
                                          <DashboardMenuContent>
                                                <FilterContainer>
                                                      {comboFlights?.length >
                                                      0 ? (
                                                            <ComboFilter
                                                                  lists={
                                                                        departures
                                                                  }
                                                                  returnStatus={
                                                                        false
                                                                  }
                                                                  searchDetail={
                                                                        searchDetail
                                                                  }
                                                                  flights={
                                                                        comboFlights
                                                                  }
                                                                  stopLists={
                                                                        stops
                                                                  }
                                                                  arrivalLists={
                                                                        arrivals
                                                                  }
                                                                  setFilteredFlights={
                                                                        setFilteredComboFlights
                                                                  }
                                                                  searchDetails={
                                                                        searchDetails
                                                                  }
                                                            />
                                                      ) : searchDetail?.returnStatus ===
                                                        "false" ? (
                                                            <OnewayFilter
                                                                  lists={
                                                                        departures
                                                                  }
                                                                  returnStatus={
                                                                        false
                                                                  }
                                                                  searchDetail={
                                                                        searchDetail
                                                                  }
                                                                  flights={
                                                                        flights
                                                                  }
                                                                  stopLists={
                                                                        stops
                                                                  }
                                                                  arrivalLists={
                                                                        arrivals
                                                                  }
                                                                  setFilteredFlights={
                                                                        setFilteredFlights
                                                                  }
                                                                  searchDetails={
                                                                        searchDetails
                                                                  }
                                                                  returnAirlines={
                                                                        returnAirlines
                                                                  }
                                                                  setReturnAirlines={
                                                                        setReturnAirlines
                                                                  }
                                                            />
                                                      ) : (
                                                            <RoundtripFilterContainer defaultActiveKey="Oneway">
                                                                  <RoundtripFilterItem
                                                                        eventKey="Oneway"
                                                                        title="Oneway"
                                                                  >
                                                                        <OnewayFilter
                                                                              lists={
                                                                                    departures
                                                                              }
                                                                              searchDetail={
                                                                                    searchDetail
                                                                              }
                                                                              returnStatus={
                                                                                    true
                                                                              }
                                                                              flights={
                                                                                    flights
                                                                              }
                                                                              stopLists={
                                                                                    stops
                                                                              }
                                                                              arrivalLists={
                                                                                    arrivals
                                                                              }
                                                                              setFilteredFlights={
                                                                                    setFilteredFlights
                                                                              }
                                                                              searchDetails={
                                                                                    searchDetails
                                                                              }
                                                                              returnAirlines={
                                                                                    returnAirlines
                                                                              }
                                                                              setReturnAirlines={
                                                                                    setReturnAirlines
                                                                              }
                                                                        />
                                                                  </RoundtripFilterItem>
                                                                  <RoundtripFilterItem
                                                                        eventKey="RoundTrip"
                                                                        title="Round Trip"
                                                                  >
                                                                        <RoundtripFilter
                                                                              searchDetail={
                                                                                    searchDetail
                                                                              }
                                                                              lists={
                                                                                    departures
                                                                              }
                                                                              flights={
                                                                                    returnFlights
                                                                              }
                                                                              stopLists={
                                                                                    stops
                                                                              }
                                                                              arrivalLists={
                                                                                    arrivals
                                                                              }
                                                                              setFilteredFlights={
                                                                                    setReturnFilterFlights
                                                                              }
                                                                              searchDetails={
                                                                                    searchDetails
                                                                              }
                                                                              returnAirlines={
                                                                                    returnAirlines
                                                                              }
                                                                              setReturnAirlines={
                                                                                    setReturnAirlines
                                                                              }
                                                                        />
                                                                  </RoundtripFilterItem>
                                                            </RoundtripFilterContainer>
                                                      )}
                                                </FilterContainer>
                                          </DashboardMenuContent>
                                    </DashboardMenu>

                                    <DashboardContent>
                                          <FlightHeader>
                                                <FlightHeaderInfo>
                                                      {searchDetail?.returnStatus ===
                                                            "false" && (
                                                            <h5>
                                                                  {
                                                                        filteredFlights?.length
                                                                  }{" "}
                                                                  Flight From{" "}
                                                                  {
                                                                        searchDetails
                                                                              ?.leaving_from
                                                                              ?.city
                                                                  }{" "}
                                                                  To{" "}
                                                                  {
                                                                        searchDetails
                                                                              ?.destination
                                                                              ?.city
                                                                  }
                                                            </h5>
                                                      )}
                                                      {comboFlights?.length >
                                                            0 && (
                                                            <h5>
                                                                  {
                                                                        filteredComboFlights?.length
                                                                  }{" "}
                                                                  Flight From{" "}
                                                                  {
                                                                        searchDetails
                                                                              ?.leaving_from
                                                                              ?.city
                                                                  }{" "}
                                                                  To{" "}
                                                                  {
                                                                        searchDetails
                                                                              ?.destination
                                                                              ?.city
                                                                  }
                                                            </h5>
                                                      )}
                                                </FlightHeaderInfo>
                                                <FlightHeaderContent>
                                                      {totalTraveller() > 1 && (
                                                            <div
                                                                  className={`header__content-item ${
                                                                        fullFare
                                                                              ? "active"
                                                                              : ""
                                                                  }`}
                                                                  onClick={() =>
                                                                        setFullFare(
                                                                              !fullFare
                                                                        )
                                                                  }
                                                            >
                                                                  {fullFare
                                                                        ? "Hide"
                                                                        : "Show"}{" "}
                                                                  Full Fare
                                                            </div>
                                                      )}
                                                      <div
                                                            className={`header__content-item ${
                                                                  netFare
                                                                        ? "active"
                                                                        : ""
                                                            }`}
                                                            onClick={() =>
                                                                  setNetFare(
                                                                        !netFare
                                                                  )
                                                            }
                                                      >
                                                            {netFare
                                                                  ? "Hide"
                                                                  : "Show"}{" "}
                                                            Net Fare
                                                      </div>
                                                </FlightHeaderContent>
                                          </FlightHeader>
                                          <DashboardFlightRanges>
                                                <DashboardFlightWrapper>
                                                      {comboFlights?.length >
                                                      0 ? (
                                                            <ComboFlight
                                                                  // flights={filteredComboFlights?.slice(
                                                                  //       0,
                                                                  //       200
                                                                  // )}
                                                                  flights={
                                                                        filteredComboFlights
                                                                  }
                                                                  setFilteredFlights={
                                                                        setFilteredComboFlights
                                                                  }
                                                                  netFare={
                                                                        netFare
                                                                  }
                                                                  searchDetail={
                                                                        searchDetail
                                                                  }
                                                                  fullFare={
                                                                        fullFare
                                                                  }
                                                                  domesticStatus={
                                                                        searchDetails
                                                                              ?.destination
                                                                              ?.country ===
                                                                        searchDetails
                                                                              ?.leaving_from
                                                                              ?.country
                                                                  }
                                                            />
                                                      ) : searchDetail?.returnStatus ===
                                                        "false" ? (
                                                            <>
                                                                  <OnewayFlight
                                                                        flights={
                                                                              filteredFlights
                                                                        }
                                                                        setFilteredFlights={
                                                                              setFilteredFlights
                                                                        }
                                                                        domesticStatus={
                                                                              searchDetails
                                                                                    ?.destination
                                                                                    ?.country ===
                                                                              searchDetails
                                                                                    ?.leaving_from
                                                                                    ?.country
                                                                        }
                                                                        netFare={
                                                                              netFare
                                                                        }
                                                                        searchDetail={
                                                                              searchDetail
                                                                        }
                                                                        fullFare={
                                                                              fullFare
                                                                        }
                                                                  />
                                                            </>
                                                      ) : (
                                                            <RoundtripFlight
                                                                  filteredFlights={
                                                                        filteredFlights
                                                                  }
                                                                  setFilteredFlights={
                                                                        setFilteredFlights
                                                                  }
                                                                  returnFilterFlights={
                                                                        returnFilterFlights
                                                                  }
                                                                  setReturnFilterFlights={
                                                                        setReturnFilterFlights
                                                                  }
                                                                  domesticStatus={
                                                                        searchDetails
                                                                              ?.destination
                                                                              ?.country ===
                                                                        searchDetails
                                                                              ?.leaving_from
                                                                              ?.country
                                                                  }
                                                                  netFare={
                                                                        netFare
                                                                  }
                                                                  searchDetail={
                                                                        searchDetail
                                                                  }
                                                                  fullFare={
                                                                        fullFare
                                                                  }
                                                            />
                                                      )}
                                                </DashboardFlightWrapper>
                                          </DashboardFlightRanges>
                                    </DashboardContent>
                              </div>
                        </DashboardContainer>
                  ) : (
                        <DashboardContainer>
                              <div className="col-lg-12">
                                    <FlightLoader />
                                    <FlightModalInfo>
                                          <div className="main__title">
                                                {`${searchDetail?.leavingFrom} - ${searchDetail?.destination}`}
                                          </div>
                                          <div className="main__subtitle">
                                                {`${convertDate(
                                                      searchDetail?.departDate
                                                )} ${
                                                      searchDetail?.returnStatus ===
                                                      "true"
                                                            ? "to " +
                                                              convertDate(
                                                                    searchDetail?.returnDate
                                                              )
                                                            : ""
                                                }`}
                                          </div>
                                    </FlightModalInfo>
                              </div>
                        </DashboardContainer>
                  )}
            </>
      );
};

export default FlightSearch;
