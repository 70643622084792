import React, { useState } from "react";
import { Alert, Form, FormControl, Spinner } from "react-bootstrap";
import $ from "jquery";
import toast from "react-hot-toast";
import { publicApi } from "../../../api";
import CommonButton from "../../Common/CommonButton";
import { SendButton } from "./style";

const EmailVerify = ({ setVerifyStatus, email, slug, setSlug }) => {
      const [code, setCode] = useState("");
      const [loading, setLoading] = useState(false);

      const [resend, setResend] = useState(false);
      const [resendLoading, setResendLoading] = useState(false);

      async function handleMailSend(event) {
            event.preventDefault();
            setLoading(true);
            $("button[type=submit]").prop("disabled", true);
            const data = {
                  code: code,
            };
            await publicApi
                  .post(`/auth/password/${slug}/verify`, data)
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    setCode("");
                                    setVerifyStatus(true);
                              }

                              if (response.data.result === "error") {
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                                    if (response.data.message) {
                                          if (response.data.message.code) {
                                                toast.error(
                                                      response.data.message.code
                                                );
                                          }
                                    }
                              }
                              $("button[type=submit]").prop("disabled", false);
                              setLoading(false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                              toast.error(error.message);
                        }, 3000);
                  });
      }

      async function handleResend() {
            setResendLoading(true);

            if (email === "") {
                  setTimeout(() => {
                        setResendLoading(false);

                        toast.error("Please enter your email address.");
                  }, 3000);
            } else {
                  const data = {
                        email: email,
                  };
                  await publicApi
                        .post(`/auth/password/reset`, data)
                        .then((response) => {
                              setTimeout(() => {
                                    setResendLoading(false);

                                    if (response.data.result === "success") {
                                          setResend(true);
                                          setSlug(response.data.slug);
                                    }

                                    if (response.data.result === "error") {
                                          if (response.data.message.email) {
                                                toast.error(
                                                      response.data.message
                                                            .email
                                                );
                                          }
                                    }
                              }, 3000);
                        })
                        .catch((error) => {
                              setTimeout(() => {
                                    setResendLoading(false);
                                    toast.error(error.message);
                              }, 3000);
                        });
            }
      }

      return (
            <>
                  <div>
                        {resend && (
                              <Alert variant="success" role="alert" dismissible>
                                    A fresh verification code has been sent to
                                    your email address.
                              </Alert>
                        )}
                        Please check your emails for a message with your code.{" "}
                        <br /> Your code is 6 numbers long. If you did not
                        receive the email
                        <SendButton
                              type="button"
                              onClick={handleResend}
                              className="btn btn-link ps-1 text-emerald-500 font-bold"
                              data-abc="true"
                        >
                              Send Again
                              {resendLoading ? <Spinner /> : ""}
                        </SendButton>
                        <Form onSubmit={handleMailSend}>
                              <div className="row g-3 mt-2 align-items-center">
                                    <div className="form-group col-lg-6">
                                          <FormControl
                                                placeholder="Enter code"
                                                type="text"
                                                value={code}
                                                onChange={(event) =>
                                                      setCode(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </div>
                                    <div className="">
                                          <CommonButton
                                                button={true}
                                                type="submit"
                                                loading={loading}
                                                title="Continue"
                                          />
                                    </div>
                              </div>
                        </Form>
                  </div>
            </>
      );
};

export default EmailVerify;
