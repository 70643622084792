import React, { useState } from "react";
import $ from "jquery";
import { Alert, Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";
import { LoginInput } from "../../form/FormInput/style";
import { LockIcon, NonVisibilityIcon } from "../../../icons";
import CommonButton from "../../Common/CommonButton";
import { publicApi } from "../../../api";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const ForgetPasswordSection = ({ slug }) => {
      const [password, setPassword] = useState("");
      const [success, setSuccess] = useState(false);
      const [showPassword, setShowPassword] = useState(false);
      const [showRegisterPassword, setShowRegisterPassword] = useState(false);
      const [confirmationPassword, setConfirmationPassword] = useState("");
      const [loading, setLoading] = useState(false);

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  password: password,
                  password_confirmation: confirmationPassword,
            };

            await publicApi
                  .post(`/auth/password/${slug}/change`, data, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    setPassword("");
                                    setConfirmationPassword("");
                                    setSuccess(true);
                              }
                              if (response.data.result === "error") {
                                    if (response.data.message.password) {
                                          toast.error(
                                                response.data.message.password
                                          );
                                    }
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                              }
                              setLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                              toast.error(error.message);
                        }, 3000);
                  });
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row className="g-3">
                              {success && (
                                    <Alert
                                          variant="success"
                                          role="alert"
                                          dismissible
                                    >
                                          Your Password has been changed. Try
                                          login from <Link to="/">here</Link>
                                    </Alert>
                              )}
                              <Col lg={8}>
                                    <FormLabel>New Password</FormLabel>
                                    <LoginInput className="ps-2 ">
                                          <LockIcon />
                                          <FormControl
                                                type={
                                                      showRegisterPassword
                                                            ? "text"
                                                            : "password"
                                                }
                                                onChange={(event) =>
                                                      setPassword(
                                                            event.target.value
                                                      )
                                                }
                                                value={password}
                                          />
                                          <NonVisibilityIcon
                                                show={showRegisterPassword}
                                                setShowPassword={
                                                      setShowRegisterPassword
                                                }
                                                classes="pe-2 border-start ps-2"
                                          />
                                    </LoginInput>
                              </Col>
                              <Col lg={8}>
                                    <FormLabel>Confirm password</FormLabel>
                                    <LoginInput className="ps-2 ">
                                          <LockIcon />
                                          <FormControl
                                                type={
                                                      showRegisterPassword
                                                            ? "text"
                                                            : "password"
                                                }
                                                onChange={(event) =>
                                                      setConfirmationPassword(
                                                            event.target.value
                                                      )
                                                }
                                                value={confirmationPassword}
                                          />
                                          <NonVisibilityIcon
                                                show={showPassword}
                                                setShowPassword={
                                                      setShowPassword
                                                }
                                                classes="pe-2 border-start ps-2"
                                          />
                                    </LoginInput>
                              </Col>
                              <Col lg={12} className="text-center">
                                    <CommonButton
                                          button={true}
                                          loading={loading}
                                          type="submit"
                                          title="Submit"
                                    />
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ForgetPasswordSection;
