import React, { useState } from "react";
import { PreloaderContainer } from "../../../components/Common/Preloader/styles";
import InfoModal from "../InfoModal";
import {
      LoginAction,
      LoginButton,
      LoginButtonContainer,
      LoginContainer,
      LoginDetail,
      LoginDetailContent,
      LoginFormContainer,
      LoginLink,
      LoginTitle,
} from "../styles";
import { Container, Row } from "react-bootstrap";
import LoginForm from "../LoginForm";
import SignupForm from "../SignupForm";

const LoginPage = () => {
      const [activeForm, setActiveForm] = useState("login");

      const handleRegister = () => {
            setActiveForm("register");
      };

      const handleLogin = (value) => {
            setActiveForm(value);
      };

      return (
            <>
                  <div className="position-relative">
                        <PreloaderContainer position="absolute">
                              <InfoModal />
                              <LoginContainer
                                    paddingTop="15vh"
                                    mobilePadding="20vh"
                              >
                                    <Container className="h-100" fluid>
                                          <Row className="h-100">
                                                <LoginDetail
                                                      className="d-flex h-100"
                                                      lg={12}
                                                >
                                                      <LoginDetailContent
                                                            lg={7}
                                                            overFlow={
                                                                  activeForm ===
                                                                  "login"
                                                                        ? "hidden"
                                                                        : ""
                                                            }
                                                      >
                                                            <LoginAction>
                                                                  <LoginTitle>
                                                                        Welcome
                                                                        to
                                                                        Aircasa
                                                                  </LoginTitle>
                                                                  <LoginButtonContainer
                                                                        activeForm={
                                                                              activeForm
                                                                        }
                                                                  >
                                                                        <LoginButton
                                                                              onClick={() =>
                                                                                    handleLogin(
                                                                                          "login"
                                                                                    )
                                                                              }
                                                                              className="p-3"
                                                                        >
                                                                              Login
                                                                        </LoginButton>
                                                                        <LoginButton
                                                                              onClick={
                                                                                    handleRegister
                                                                              }
                                                                              className="p-3"
                                                                        >
                                                                              Sign
                                                                              Up
                                                                        </LoginButton>
                                                                  </LoginButtonContainer>
                                                            </LoginAction>

                                                            <LoginFormContainer>
                                                                  <LoginForm
                                                                        activeForm={
                                                                              activeForm
                                                                        }
                                                                        handleLogin={
                                                                              handleLogin
                                                                        }
                                                                  />
                                                                  <SignupForm
                                                                        activeForm={
                                                                              activeForm
                                                                        }
                                                                  />
                                                            </LoginFormContainer>
                                                            <LoginLink
                                                                  to="tel:+918766601888"
                                                                  color="#333"
                                                            >
                                                                  Need help?
                                                                  Give us a call
                                                                  at
                                                                  +918766601888{" "}
                                                            </LoginLink>
                                                      </LoginDetailContent>
                                                </LoginDetail>
                                          </Row>
                                    </Container>
                              </LoginContainer>
                        </PreloaderContainer>
                  </div>
            </>
      );
};

export default LoginPage;
