import styled from "styled-components";

export const OtherServiceContainer = styled.div`
      ul {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            list-style: none;
            gap: 15px;
            padding: 0;
            margin-bottom: 1.5rem;

            li {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  padding: 5px 10px;
                  border-radius: 5px;
                  border: 1px solid #eaeaea;
                  background-color: #f4f4f4;

                  span {
                        font-size: 15px;
                  }
            }
      }
`;

export const ServiceButton = styled.div`
      border: 1px solid ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
      transition: all 0.3s ease-in-out;
      display: inline-block;
      padding: 5px 30px;
      text-align: center;
      border-radius: 2rem;
      cursor: pointer;
      font-size: 15px;
      min-width: 185px;

      .spinner-border {
            width: 1rem !important;
            height: 1rem !important;
      }

      &:hover,
      &.active {
            background-color: ${(props) => props.theme.primary};
            color: ${(props) => props.theme.white};
      }
`;

export const ServiceContact = styled.div`
      .service__contact-title {
            font-size: 15px;
            font-weight: 510;
      }
      .text-muted {
            font-size: 14px;
      }
`;
