import React from "react";
import { FlightReviewContent } from "../../flight_reviews/style";
import { PriceSummary } from "../../checkout/styles";
import PriceSummaryContent from "../../../components/Frontend/checkout/PriceSummaryBox/PriceSummaryContent";
import { convertAmount, convertFloat } from "../../../hooks/CurrentData";
import { Skeleton } from "@mui/material";
import { ServiceContact } from "./style";
import { FaPhoneAlt } from "react-icons/fa";
import FlightBookingRate from "../FlightBookingRate";
import RaiseRequest from "../RaiseRequest";

const FlightConfirmPayment = ({
      totalPriceInfo,
      travellerInfos,
      agentOrder,
      loading,
      bookingId,
      setShow,
      tripInfos,
}) => {
      // Function to calculate total amounts of Seat, Meal, and Baggage
      function calculateTotalAmounts(data) {
            let totalSeatAmount = 0;
            let totalMealAmount = 0;
            let totalBaggageAmount = 0;

            // Iterate through each data entry (segment)
            data.forEach((entry) => {
                  // Calculate total Seat amount
                  Object.values(entry.ssrSeatInfos || {}).forEach(
                        (seatInfo) => {
                              if (seatInfo.amount !== undefined) {
                                    totalSeatAmount += seatInfo.amount;
                              }
                        }
                  );

                  // Calculate total Meal amount
                  Object.values(entry.ssrMealInfos || {}).forEach(
                        (mealInfo) => {
                              if (mealInfo.amount !== undefined) {
                                    totalMealAmount += mealInfo.amount;
                              }
                        }
                  );

                  // Calculate total Baggage amount
                  Object.values(entry.ssrBaggageInfos || {}).forEach(
                        (baggageInfo) => {
                              if (baggageInfo.amount !== undefined) {
                                    totalBaggageAmount += baggageInfo.amount;
                              }
                        }
                  );
            });

            // Return the total amounts for each service
            return {
                  totalSeatAmount,
                  totalMealAmount,
                  totalBaggageAmount,
            };
      }

      const totalAmount = () => {
            let totalAmount = 0;
            totalAmount += totalPriceInfo?.fC?.TF;
            if (agentOrder?.seriesFareStatus === 1) {
                  totalAmount += agentOrder?.commission;
            }
            if (agentOrder?.service_charge) {
                  totalAmount += parseFloat(agentOrder.service_charge);
            }
            return totalAmount;
      };

      const afterOtherTaxes = () => {
            let taxes = totalPriceInfo?.fC?.TAF;
            if (agentOrder?.service_charge) {
                  taxes += parseFloat(agentOrder?.service_charge);
            }

            if (agentOrder.seriesFareStatus === 1) {
                  taxes += agentOrder?.commission;
            }
            return taxes;
      };

      const otherTaxes = () => {
            let taxes = totalPriceInfo?.afC?.TAF?.OT;
            if (agentOrder?.seriesFareStatus === 1) {
                  taxes += agentOrder?.commission;
            }
            return taxes;
      };

      return (
            <>
                  <FlightReviewContent>
                        <RaiseRequest
                              setShowMessage={setShow}
                              travellerInfos={travellerInfos}
                              bookingId={bookingId}
                              agentOrder={agentOrder}
                              tripInfos={tripInfos}
                        />
                        <PriceSummary>
                              <div className="price__box py-3 mb-4">
                                    <div className="price__heading">
                                          <div className="price__title w-75">
                                                Payment Summary
                                          </div>
                                    </div>
                                    <ul className="price__list list-inline mb-0 border-bottom">
                                          <li className="price__item d-flex align-items-center">
                                                <div className="price__item--title w-75">
                                                      Base Fare
                                                </div>
                                                <div className="price__item--amount">
                                                      {!loading ? (
                                                            `₹ ${convertAmount(
                                                                  convertFloat(
                                                                        totalPriceInfo
                                                                              ?.fC
                                                                              ?.BF
                                                                  )
                                                            )}`
                                                      ) : (
                                                            <Skeleton
                                                                  height="20px"
                                                                  width={"80px"}
                                                                  style={{
                                                                        transform:
                                                                              "scale(1)",
                                                                  }}
                                                            />
                                                      )}
                                                </div>
                                          </li>
                                    </ul>
                                    <PriceSummaryContent
                                          title="Taxes & Fees"
                                          border="0px"
                                          totalServicesAmount={
                                                !loading ? (
                                                      `₹ ${convertAmount(
                                                            convertFloat(
                                                                  afterOtherTaxes()
                                                            )
                                                      )}`
                                                ) : (
                                                      <Skeleton
                                                            height="20px"
                                                            width={"80px"}
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                      />
                                                )
                                          }
                                    >
                                          <ul className="price__list list-inline mb-0">
                                                {totalPriceInfo?.afC?.TAF
                                                      ?.AGST ? (
                                                      <li className="price__item  d-flex align-items-center">
                                                            <div className="price__item--title w-75">
                                                                  Airline GST
                                                            </div>
                                                            <div className="price__item--amount">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalPriceInfo
                                                                                    ?.afC
                                                                                    ?.TAF
                                                                                    ?.AGST
                                                                        )
                                                                  )}
                                                            </div>
                                                      </li>
                                                ) : (
                                                      ""
                                                )}
                                                {totalPriceInfo?.afC?.TAF
                                                      ?.OT ? (
                                                      <li className="price__item  d-flex align-items-center">
                                                            <div className="price__item--title w-75">
                                                                  Other Taxes
                                                            </div>
                                                            <div className="price__item--amount">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              otherTaxes()
                                                                        )
                                                                  )}
                                                            </div>
                                                      </li>
                                                ) : (
                                                      ""
                                                )}
                                                {totalPriceInfo?.afC?.TAF
                                                      ?.MF ? (
                                                      <li className="price__item  d-flex align-items-center">
                                                            <div className="price__item--title w-75">
                                                                  Management Fee
                                                            </div>
                                                            <div className="price__item--amount">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalPriceInfo
                                                                                    ?.afC
                                                                                    ?.TAF
                                                                                    ?.MF
                                                                        )
                                                                  )}
                                                            </div>
                                                      </li>
                                                ) : (
                                                      ""
                                                )}
                                                {totalPriceInfo?.afC?.TAF
                                                      ?.MFT ? (
                                                      <li className="price__item  d-flex align-items-center">
                                                            <div className="price__item--title w-75">
                                                                  Management Fee
                                                                  Tax
                                                            </div>
                                                            <div className="price__item--amount">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalPriceInfo
                                                                                    ?.afC
                                                                                    ?.TAF
                                                                                    ?.MFT
                                                                        )
                                                                  )}
                                                            </div>
                                                      </li>
                                                ) : (
                                                      ""
                                                )}
                                                {totalPriceInfo?.afC?.TAF
                                                      ?.YR ? (
                                                      <li className="price__item  d-flex align-items-center">
                                                            <div className="price__item--title w-75">
                                                                  Carrier Misc
                                                                  Fee
                                                            </div>
                                                            <div className="price__item--amount">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalPriceInfo
                                                                                    ?.afC
                                                                                    ?.TAF
                                                                                    ?.YR
                                                                        )
                                                                  )}
                                                            </div>
                                                      </li>
                                                ) : (
                                                      ""
                                                )}
                                                {totalPriceInfo?.afC?.TAF
                                                      ?.YQ ? (
                                                      <li className="price__item  d-flex align-items-center">
                                                            <div className="price__item--title w-75">
                                                                  Fuel Surcharge
                                                            </div>
                                                            <div className="price__item--amount">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalPriceInfo
                                                                                    ?.afC
                                                                                    ?.TAF
                                                                                    ?.YQ
                                                                        )
                                                                  )}
                                                            </div>
                                                      </li>
                                                ) : (
                                                      ""
                                                )}
                                                {agentOrder?.service_charge ? (
                                                      <li className="price__item  d-flex align-items-center">
                                                            <div className="price__item--title w-75">
                                                                  Service
                                                                  Charges
                                                            </div>
                                                            <div className="price__item--amount">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              agentOrder?.service_charge
                                                                        )
                                                                  )}
                                                            </div>
                                                      </li>
                                                ) : (
                                                      ""
                                                )}
                                          </ul>
                                    </PriceSummaryContent>
                                    {totalPriceInfo?.fC?.SSRP ? (
                                          <PriceSummaryContent
                                                title="Add Ons"
                                                border="0px"
                                                totalServicesAmount={convertAmount(
                                                      convertFloat(
                                                            totalPriceInfo?.fC
                                                                  ?.SSRP
                                                      )
                                                )}
                                          >
                                                <ul className="price__list list-inline mb-0">
                                                      <li className="price__item  d-flex align-items-center">
                                                            <div className="price__item--title w-75">
                                                                  Baggage
                                                            </div>
                                                            <div className="price__item--amount">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              calculateTotalAmounts(
                                                                                    travellerInfos
                                                                              )
                                                                                    ?.totalBaggageAmount
                                                                        )
                                                                  )}
                                                            </div>
                                                      </li>
                                                      <li className="price__item  d-flex align-items-center">
                                                            <div className="price__item--title w-75">
                                                                  Meal
                                                            </div>
                                                            <div className="price__item--amount">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              calculateTotalAmounts(
                                                                                    travellerInfos
                                                                              )
                                                                                    ?.totalMealAmount
                                                                        )
                                                                  )}
                                                            </div>
                                                      </li>
                                                      <li className="price__item  d-flex align-items-center">
                                                            <div className="price__item--title w-75">
                                                                  Seat
                                                            </div>
                                                            <div className="price__item--amount">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              calculateTotalAmounts(
                                                                                    travellerInfos
                                                                              )
                                                                                    ?.totalSeatAmount
                                                                        )
                                                                  )}
                                                            </div>
                                                      </li>
                                                </ul>
                                          </PriceSummaryContent>
                                    ) : null}
                                    <div className="total d-flex align-items-center mb-0">
                                          <div className="total__title  w-75">
                                                Grand Total
                                          </div>
                                          <div className="total__price">
                                                {!loading ? (
                                                      `₹ ${convertAmount(
                                                            convertFloat(
                                                                  totalAmount()
                                                            )
                                                      )}`
                                                ) : (
                                                      <Skeleton
                                                            height="20px"
                                                            width={"80px"}
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                      />
                                                )}
                                          </div>
                                    </div>
                              </div>
                        </PriceSummary>
                        <FlightBookingRate
                              bookingId={bookingId}
                              agentOrder={agentOrder}
                        />

                        <PriceSummary>
                              <div className="price__box py-2 mb-4">
                                    <div className="price__heading mb-0">
                                          <div className="price__title d-flex align-items-center gap-3 w-75">
                                                <FaPhoneAlt
                                                      color="#224082"
                                                      size="24px"
                                                />
                                                <ServiceContact>
                                                      <div className="service__contact-title">
                                                            Flight Support
                                                      </div>
                                                      <div className="text-muted">
                                                            Call us at: +91
                                                            8766601888
                                                      </div>
                                                </ServiceContact>
                                          </div>
                                    </div>
                              </div>
                        </PriceSummary>
                  </FlightReviewContent>
            </>
      );
};

export default FlightConfirmPayment;
