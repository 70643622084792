import { Link } from "react-router-dom";
import styled from "styled-components";
import { Swiper } from "swiper/react";

export const BannerSwiper = styled(Swiper)`
      height: 100%;

      .swiper-pagination-bullet {
            background: #7f8695;
            height: 12px;
            width: 12px;
      }

      .swiper-pagination-bullet-active {
            background: #14264e;
      }

      @media screen and (max-width: 568px) {
            position: relative;
            height: 65vh;

            .swiper-pagination {
                  position: absolute;
            }
      }
`;

export const BannerImage = styled.div`
      height: 50%;
      display: flex;
      align-items: center;

      img {
            width: 100%;
            height: 280px;
            object-fit: contain;
            margin-bottom: -120px;
            position: relative;
            z-index: 9999999;
      }

      @media screen and (max-width: 568px) {
            height: 75%;
      }
`;

export const BannerTitle = styled.div`
      text-align: center;
      font-size: 20px;
      position: relative;
      height: 50%;
      background: #224082;

      .custom-shape-divider-top-1733913168 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            line-height: 0;
      }

      .custom-shape-divider-top-1733913168 svg {
            position: relative;
            display: block;
            width: calc(300% + 1.3px);
      }

      .custom-shape-divider-top-1733913168 .shape-fill {
            fill: #ffffff;
      }

      .customer__title {
            z-index: 999;
            position: relative;
            color: ${(props) => props.theme.white};
            padding: 28px;
            padding-top: 160px;
            font-size: 28px;
      }

      @media screen and (max-width: 568px) {
            display: none;
      }
`;

export const MobileBannerTitle = styled.div`
      text-align: center;
      font-size: 20px;
      width: 100%;
      background: #224082;
      position: absolute;
      bottom: 0;
      display: none;

      @media screen and (max-width: 568px) {
            display: block;
      }

      .custom-shape-divider-top-1733913168 {
            position: absolute;
            top: -1px;
            left: 0;
            width: 100%;
            overflow: hidden;
            line-height: 0;
      }

      .custom-shape-divider-top-1733913168 svg {
            position: relative;
            display: block;
            width: calc(300% + 1.3px);
      }

      .custom-shape-divider-top-1733913168 .shape-fill {
            fill: #ffffff;
      }

      .customer__title {
            z-index: 999;
            position: relative;
            color: ${(props) => props.theme.white};
            padding: 28px;
            padding-top: 110px;
            padding-bottom: 50px;
            font-size: 16px;
      }
`;

export const MobileLoginButton = styled(Link)`
      padding: 5px 20px;
      background-color: ${(props) => props.theme.white};
      border-radius: 25rem;
      display: none;
      color: ${(props) => props.theme.primary};
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      @media screen and (max-width: 568px) {
            display: inline-block;
      }
`;

export const BannerCardContainer = styled.div`
      box-shadow: 0px 4px 12.3px 0px #00000040;
      background: #224082;
      height: 100%;
      width: 100%;
      padding: 20px;
      border-radius: 10px;

      p {
            color: ${(props) => props.theme.white};
            text-align: center;
            font-size: 14px;
            margin: 0;
            margin-top: 10px;
      }

      @media screen and (max-width: 568px) {
            p {
                  display: none;
            }
      }
`;

export const BannerCardImage = styled.div`
      height: 80px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;

      img {
            object-fit: contain;
      }

      @media screen and (max-width: 568px) {
            height: 50px;
            margin-top: 10px;

            img {
                  height: 100%;
                  width: 100%;
            }
      }
`;

export const BannerCardContent = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 15%;
      margin-top: 20vh;

      @media screen and (max-width: 568px) {
            margin: 0 10%;
            margin-top: 15vh;
      }
`;

export const BannerCardInfo = styled.div`
      display: grid;
      align-items: center;
      gap: 30px;
      grid-template-columns: 1fr 1fr;
`;

export const BannerCardTitle = styled.div`
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.08em;
      color: ${(props) => props.theme.white};
      text-align: center;

      @media screen and (max-width: 568px) {
            font-size: 16px;
            font-weight: 600;
      }
`;
