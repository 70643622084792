import React, { useEffect, useState } from "react";
import { RescheduleDepartureContent } from "../style";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { convertAmount, convertFloat } from "../../../../hooks/CurrentData";
import CommonButton from "../../../../components/Common/CommonButton";
import { Link } from "react-router-dom";
import { Alert as AlertContainer } from "react-bootstrap";
import { CancellationTable } from "./styles";
import { tripJackApi } from "../../../../api";

const NormalCancelForm = ({
      agentOrder,
      requestType,
      currentCancelOption,
      countTypes,
}) => {
      const [trips, setTrips] = useState([]);
      const [loading, setLoading] = useState(false);
      const [message, setMessage] = useState("");
      const categoryOrder = ["ADULT", "CHILD", "INFANT"];
      const [loadingStatus, setLoadingStatus] = useState(false);

      const checkFare = async () => {
            setLoading(true);
            const data = {
                  bookingId: agentOrder?.tj_booking_id,
                  type: "CANCELLATION",
                  remarks: "",
            };

            try {
                  await tripJackApi
                        .post(`/oms/v1/air/amendment/amendment-charges`, data, {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        })
                        .then((response) => {
                              if (response.data.trips) {
                                    setTrips(response.data.trips);
                              }
                        });
            } catch (error) {
                  console.log(error.message);
                  if (error?.response?.data?.errors) {
                        setMessage(error?.response?.data?.errors[0]);
                  }
            }
            setLoading(false);
      };

      useEffect(() => {
            if (currentCancelOption?.title === "Quote for Normal") {
                  checkFare();
            }
      }, [currentCancelOption]);

      const handleCancel = async () => {
            setLoadingStatus(true);
            const data = {
                  bookingId: agentOrder?.tj_booking_id,
                  type: "CANCELLATION",
                  remarks: "",
            };

            await tripJackApi.post(
                  `/oms/v1/air/amendment/submit-amendment`,
                  data,
                  {
                        headers: {
                              apikey: process.env.REACT_APP_Flight_Api_Key,
                        },
                  }
            );
            setLoadingStatus(false);
      };

      return (
            <>
                  <Form>
                        <RescheduleDepartureContent>
                              {!loading ? (
                                    trips?.length > 0 ? (
                                          <>
                                                <Row>
                                                      {trips.map(
                                                            (trip, index) => {
                                                                  // Calculate total amendment charges, refund amount, and total fare
                                                                  const totalAmendmentCharges =
                                                                        Object.entries(
                                                                              trip.amendmentInfo
                                                                        ).reduce(
                                                                              (
                                                                                    sum,
                                                                                    [
                                                                                          type,
                                                                                          info,
                                                                                    ]
                                                                              ) =>
                                                                                    sum +
                                                                                    info.amendmentCharges *
                                                                                          (countTypes[
                                                                                                type
                                                                                          ] ||
                                                                                                0),
                                                                              0
                                                                        );

                                                                  const totalRefundAmount =
                                                                        Object.entries(
                                                                              trip.amendmentInfo
                                                                        ).reduce(
                                                                              (
                                                                                    sum,
                                                                                    [
                                                                                          type,
                                                                                          info,
                                                                                    ]
                                                                              ) =>
                                                                                    sum +
                                                                                    info.refundAmount *
                                                                                          (countTypes[
                                                                                                type
                                                                                          ] ||
                                                                                                0),
                                                                              0
                                                                        );
                                                                  return (
                                                                        <Col
                                                                              lg={
                                                                                    6
                                                                              }
                                                                              key={
                                                                                    index
                                                                              }
                                                                              className="trip-card"
                                                                        >
                                                                              <h3 className="fs-5">
                                                                                    {
                                                                                          trip.src
                                                                                    }{" "}
                                                                                    →{" "}
                                                                                    {
                                                                                          trip.dest
                                                                                    }
                                                                              </h3>
                                                                              <p className="mb-1">
                                                                                    <strong>
                                                                                          Departure:
                                                                                    </strong>{" "}
                                                                                    {new Date(
                                                                                          trip.departureDate
                                                                                    ).toLocaleString()}
                                                                              </p>
                                                                              <p className="mb-1">
                                                                                    <strong>
                                                                                          Flight
                                                                                          Number:
                                                                                    </strong>{" "}
                                                                                    {trip.flightNumbers.join(
                                                                                          ", "
                                                                                    )}
                                                                              </p>
                                                                              <p>
                                                                                    <strong>
                                                                                          Airline:
                                                                                    </strong>{" "}
                                                                                    {trip.airlines.join(
                                                                                          ", "
                                                                                    )}
                                                                              </p>
                                                                              <h4 className="fs-6">
                                                                                    Cancellation
                                                                                    Charges:
                                                                              </h4>
                                                                              <CancellationTable
                                                                                    bordered
                                                                              >
                                                                                    <thead>
                                                                                          <tr>
                                                                                                <th>
                                                                                                      Category
                                                                                                </th>
                                                                                                <th>
                                                                                                      Cancellation
                                                                                                      Charges
                                                                                                </th>
                                                                                                <th>
                                                                                                      Refund
                                                                                                      Amount
                                                                                                </th>
                                                                                          </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                          {categoryOrder
                                                                                                .filter(
                                                                                                      (
                                                                                                            category
                                                                                                      ) =>
                                                                                                            trip
                                                                                                                  .amendmentInfo[
                                                                                                                  category
                                                                                                            ]
                                                                                                ) // Ensure only existing categories are shown
                                                                                                .map(
                                                                                                      (
                                                                                                            category
                                                                                                      ) => (
                                                                                                            <tr
                                                                                                                  key={
                                                                                                                        category
                                                                                                                  }
                                                                                                            >
                                                                                                                  <td>
                                                                                                                        {
                                                                                                                              category
                                                                                                                        }
                                                                                                                  </td>
                                                                                                                  <td>
                                                                                                                        ₹
                                                                                                                        {convertAmount(
                                                                                                                              convertFloat(
                                                                                                                                    trip
                                                                                                                                          .amendmentInfo[
                                                                                                                                          category
                                                                                                                                    ]
                                                                                                                                          ?.amendmentCharges
                                                                                                                              )
                                                                                                                        )}{" "}
                                                                                                                        x{" "}
                                                                                                                        {
                                                                                                                              countTypes[
                                                                                                                                    category
                                                                                                                              ]
                                                                                                                        }
                                                                                                                  </td>
                                                                                                                  <td>
                                                                                                                        ₹
                                                                                                                        {convertAmount(
                                                                                                                              convertFloat(
                                                                                                                                    trip
                                                                                                                                          .amendmentInfo[
                                                                                                                                          category
                                                                                                                                    ]
                                                                                                                                          ?.refundAmount
                                                                                                                              )
                                                                                                                        )}{" "}
                                                                                                                        x{" "}
                                                                                                                        {
                                                                                                                              countTypes[
                                                                                                                                    category
                                                                                                                              ]
                                                                                                                        }
                                                                                                                  </td>
                                                                                                            </tr>
                                                                                                      )
                                                                                                )}
                                                                                          {/* Total Row */}
                                                                                          <tr
                                                                                                style={{
                                                                                                      fontWeight:
                                                                                                            "bold",
                                                                                                      backgroundColor:
                                                                                                            "#f0f0f0",
                                                                                                }}
                                                                                          >
                                                                                                <td>
                                                                                                      Total
                                                                                                </td>
                                                                                                <td>
                                                                                                      ₹
                                                                                                      {convertAmount(
                                                                                                            convertFloat(
                                                                                                                  totalAmendmentCharges
                                                                                                            )
                                                                                                      )}
                                                                                                </td>
                                                                                                <td>
                                                                                                      ₹
                                                                                                      {convertAmount(
                                                                                                            convertFloat(
                                                                                                                  totalRefundAmount
                                                                                                            )
                                                                                                      )}
                                                                                                </td>
                                                                                          </tr>
                                                                                    </tbody>
                                                                              </CancellationTable>
                                                                        </Col>
                                                                  );
                                                            }
                                                      )}
                                                      <Col lg={12}>
                                                            <p>
                                                                  Note: Service
                                                                  charges ₹ 200
                                                                  has not
                                                                  included in
                                                                  this amount
                                                            </p>
                                                            <p className="text-danger">
                                                                  Amount shown
                                                                  as Refund is a
                                                                  Gross Refund,
                                                                  Commission
                                                                  will be
                                                                  recalled over
                                                                  and above the
                                                                  Refund Amount{" "}
                                                                  <br />
                                                                  Cancellation
                                                                  within 2 hrs
                                                                  of scheduled
                                                                  departure is
                                                                  not permitted{" "}
                                                            </p>
                                                      </Col>
                                                </Row>
                                                <CommonButton
                                                      loading={loadingStatus}
                                                      button={true}
                                                      width="220px"
                                                      padding="6px "
                                                      fontSize="14px"
                                                      type="button"
                                                      handleClick={handleCancel}
                                                      title="Process Cancellation"
                                                />
                                          </>
                                    ) : (
                                          <>
                                                <AlertContainer variant="danger">
                                                      {message?.details}
                                                      {". "}
                                                      For further assistance,
                                                      please contact us at{" "}
                                                      <Link to="tel:+918766601888">
                                                            +91 8766601888
                                                      </Link>
                                                      .
                                                </AlertContainer>
                                          </>
                                    )
                              ) : (
                                    <div className="text-center">
                                          <Spinner />
                                    </div>
                              )}
                        </RescheduleDepartureContent>
                  </Form>
            </>
      );
};

export default NormalCancelForm;
