import React, { useState } from "react";
import { ServiceButton } from "../../FlightConfirmPayment/style";
import { Spinner } from "react-bootstrap";
import axios from "axios";

const CheckFare = ({ handleCancelOption, options }) => {
      const [loading, setLoading] = useState(false);

      return (
            <>
                  {options?.map((item, index) => (
                        <ServiceButton
                              onClick={() => handleCancelOption(index)}
                              className={`mb-3 ${index > 0 ? "ms-3" : ""} ${
                                    item?.checked ? "active" : ""
                              }`}
                        >
                              {item?.title} {loading ? <Spinner /> : ""}
                        </ServiceButton>
                  ))}
            </>
      );
};

export default CheckFare;
