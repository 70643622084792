import React, { useEffect, useState } from "react";
import { CloseButton, Col, Modal, Row, Spinner } from "react-bootstrap";
import {
      FlightModal,
      FlightModalBody,
      FlightModalFooter,
      FlightPriceList,
      FlightRow,
      FlightSeat,
      FlightSeatContainer,
      FlightSeatContent,
      FlightSeatList,
      SeatAirlineBorder,
      SeatAirlineContainer,
      SeatAirlineImage,
      SeatAirlineInfo,
      SeatAirlineName,
      SeatAirlineSubtitle,
      SeatPriceContainer,
      SeatPriceContent,
      TravellerButton,
} from "./style";
import axios from "axios";
import "./style.css";
import { format } from "date-fns";
import CommonButton from "../../../Common/CommonButton";
import { tripJackApi } from "../../../../api";

const SeatMapList = ({
      bookingId,
      totalAmount,
      setTotalAmount,
      tripInfos,
      serviceList,
      setServiceList,
      serviceType,
      totalNetAmount,
      setTotalNetAmount,
}) => {
      // console.log(serviceList);
      const destinationKey = `${tripInfos?.da?.code}-${tripInfos?.aa?.code}`;
      const [currentTraveller, setCurrentTraveller] = useState("ADULT-1");
      const [travellerList, setTravellerList] = useState([]);

      const findingTravellers = () => {
            if (serviceList[destinationKey]) {
                  setTravellerList(serviceList[destinationKey]);
            }
      };

      const [show, setShow] = useState(false);
      const [seats, setSeats] = useState({});
      const [seatList, setSeatList] = useState([]);
      const [uniquePrice, setUniquePrice] = useState([]);
      const [loading, setLoading] = useState(false);
      const [loadingData, setLoadingData] = useState(false);
      const [seatData, setSeatData] = useState([]);

      const handleForm = async () => {
            setShow(true);
            setLoadingData(true);
            try {
                  const response = await tripJackApi.post(
                        `/fms/v1/seat`,
                        { bookingId },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  );

                  const uniqueAmounts = [
                        ...new Set(
                              response.data?.tripSeatMap?.tripSeat?.[
                                    tripInfos?.id
                              ]?.sInfo.map((seat) => seat.amount)
                        ),
                  ].sort((a, b) => a - b);

                  setUniquePrice(uniqueAmounts);

                  const seatData =
                        response.data?.tripSeatMap?.tripSeat?.[tripInfos?.id]
                              ?.sInfo || [];
                  setSeatData(
                        response.data?.tripSeatMap?.tripSeat?.[tripInfos?.id]
                              ?.sData
                  );
                  setSeatList(
                        response.data?.tripSeatMap?.tripSeat?.[tripInfos?.id]
                              ?.sInfo
                  );

                  const rows = {};

                  for (
                        let row = 1;
                        row <=
                        response.data?.tripSeatMap?.tripSeat?.[tripInfos?.id]
                              ?.sData?.row;
                        row++
                  ) {
                        rows[row] = [];
                        for (
                              let col = 1;
                              col <=
                              response.data?.tripSeatMap?.tripSeat?.[
                                    tripInfos?.id
                              ]?.sData?.column;
                              col++
                        ) {
                              const seat = seatData.find(
                                    (seat) =>
                                          seat.seatPosition.row === row &&
                                          seat.seatPosition.column === col
                              );
                              rows[row][col] = seat || {
                                    seatNo: "Non",
                                    seatPosition: { row, column: col },
                                    isBooked: false,
                                    isLegroom: false,
                                    isAisle: col === 1 || col === 7,
                                    amount: 0,
                              };
                        }
                  }

                  setSeats(rows);
            } catch (error) {
                  console.log(
                        error.response?.data?.message || "An error occurred"
                  );
            }
            setLoadingData(false);
      };

      const checkColor = (amount) => {
            for (let index = 0; index < uniquePrice.length; index++) {
                  if (uniquePrice[index] === amount) {
                        let color = "bg-" + index;
                        let status = true;
                        return { color, status };
                  }
            }
            return { color: "none", status: false };
      };

      const airlineDetail = tripInfos?.fD;
      const arriveDetail = tripInfos?.aa;
      const destinationDetail = tripInfos?.da;

      const handleServiceChange = (name, value) => {
            setServiceList((prevServices) => ({
                  ...prevServices,
                  [destinationKey]: {
                        ...prevServices[destinationKey],
                        [currentTraveller]: {
                              ...prevServices[destinationKey][currentTraveller],
                              [serviceType]: {
                                    ...prevServices[destinationKey][
                                          currentTraveller
                                    ][serviceType],
                                    [name]: value,
                              },
                        },
                  },
            }));
      };

      const findingAmount = () => {
            let totalAmount = 0;

            if (serviceList && serviceList[destinationKey]) {
                  Object.entries(serviceList[destinationKey]).forEach(
                        ([passenger, services]) => {
                              if (services[serviceType]) {
                                    totalAmount += parseFloat(
                                          services[serviceType].amount || 0
                                    );
                              }
                        }
                  );
            }

            return totalAmount.toFixed(2);
      };

      const handleSelectSeat = (code) => {
            const selectedSeat = seatList?.find((item) => {
                  return item?.code === code;
            });

            if (
                  travellerList[currentTraveller]?.Seat?.code !==
                        selectedSeat?.code &&
                  selectedSeat?.amount !==
                        travellerList[currentTraveller]?.Seat?.amount
            ) {
                  setTotalAmount(
                        parseFloat(totalAmount) +
                              parseFloat(selectedSeat?.amount) -
                              parseFloat(
                                    travellerList[currentTraveller]?.Seat
                                          ?.amount
                              )
                  );
                  setTotalNetAmount(
                        parseFloat(totalNetAmount) +
                              parseFloat(selectedSeat?.amount) -
                              parseFloat(
                                    travellerList[currentTraveller]?.Seat
                                          ?.amount
                              )
                  );
                  handleServiceChange("status", !selectedSeat?.status);
                  handleServiceChange("name", selectedSeat?.desc);
                  handleServiceChange("code", selectedSeat?.code);
                  handleServiceChange("amount", selectedSeat?.amount);
            }
            if (
                  travellerList[currentTraveller]?.Seat?.code ===
                        selectedSeat?.code &&
                  travellerList[currentTraveller]?.Seat?.amount ===
                        selectedSeat?.amount
            ) {
                  setTotalAmount(
                        parseFloat(totalAmount) -
                              parseFloat(selectedSeat?.amount)
                  );
                  setTotalNetAmount(
                        parseFloat(totalNetAmount) -
                              parseFloat(selectedSeat?.amount)
                  );
                  handleServiceChange("name", "");
                  handleServiceChange("code", "");
                  handleServiceChange("amount", 0);
                  handleServiceChange("status", false);
            } else {
                  handleServiceChange("status", !selectedSeat?.status);
                  handleServiceChange("name", selectedSeat?.desc);
                  handleServiceChange("code", selectedSeat?.code);
                  handleServiceChange("amount", selectedSeat?.amount);
            }
      };

      const checkSeat = (value) => {
            const foundTraveller =
                  travellerList[currentTraveller]?.Seat?.code === value;

            return foundTraveller;
      };

      const handleSeat = () => {
            setLoading(true);
            setTimeout(() => {
                  setLoading(false);
                  setShow(false);
            }, 3000);
      };

      const convertDate = (isoString) => {
            const date = new Date(isoString);
            return format(date, "MMMM dd, yyyy");
      };

      useEffect(() => {
            findingTravellers();
      }, [serviceList]);

      return (
            <>
                  <FlightSeatList>
                        <div>
                              <div>
                                    {`${tripInfos?.da?.city} - ${tripInfos?.aa?.city}`}
                              </div>
                              <div className="text-muted">
                                    on {convertDate(tripInfos?.dt)}
                              </div>
                        </div>
                        <div>
                              {Object.keys(travellerList)?.map((item) => {
                                    return (
                                          <>
                                                <div className="ms-2">
                                                      {travellerList[item]?.Seat
                                                            ?.code && (
                                                            <div className="d-flex">
                                                                  {item}:{" "}
                                                                  <span className="text-muted">
                                                                        {
                                                                              travellerList[
                                                                                    item
                                                                              ]
                                                                                    ?.Seat
                                                                                    ?.code
                                                                        }
                                                                  </span>
                                                            </div>
                                                      )}
                                                </div>
                                          </>
                                    );
                              })}
                        </div>
                        <TravellerButton onClick={handleForm} type="button">
                              Seat Map
                        </TravellerButton>
                  </FlightSeatList>
                  <FlightModal
                        show={show}
                        size="lg"
                        centered
                        onHide={() => setShow(false)}
                  >
                        <Modal.Body>
                              <CloseButton onClick={() => setShow(false)} />
                              <FlightModalBody>
                                    <Row className="seat-info-container g-0 h-100">
                                          <Col lg={4}>
                                                <SeatAirlineContainer>
                                                      <SeatAirlineImage>
                                                            <img
                                                                  src={`/images/AirlinesLogo/${airlineDetail?.aI?.code}.png`}
                                                                  alt=""
                                                            />
                                                      </SeatAirlineImage>
                                                      <SeatAirlineInfo className="d-flex">
                                                            <div>
                                                                  <div>
                                                                        {
                                                                              airlineDetail
                                                                                    ?.aI
                                                                                    ?.name
                                                                        }
                                                                  </div>
                                                                  <SeatAirlineSubtitle className="text-muted">
                                                                        {
                                                                              airlineDetail
                                                                                    ?.aI
                                                                                    ?.code
                                                                        }
                                                                        -
                                                                        {
                                                                              airlineDetail?.eT
                                                                        }
                                                                  </SeatAirlineSubtitle>
                                                            </div>
                                                            <SeatAirlineName>
                                                                  {
                                                                        destinationDetail?.code
                                                                  }
                                                                  {" - "}
                                                                  {
                                                                        arriveDetail?.code
                                                                  }
                                                            </SeatAirlineName>
                                                      </SeatAirlineInfo>
                                                </SeatAirlineContainer>
                                                <SeatAirlineBorder />
                                                <SeatPriceContainer>
                                                      <SeatPriceContent>
                                                            <li>
                                                                  <div>
                                                                        Passenger
                                                                  </div>
                                                                  <div>
                                                                        Seat
                                                                  </div>
                                                                  <div>Fee</div>
                                                            </li>
                                                            {Object.keys(
                                                                  travellerList
                                                            )?.map((item) => {
                                                                  return (
                                                                        <>
                                                                              <li
                                                                                    className={
                                                                                          currentTraveller ===
                                                                                          item
                                                                                                ? "active"
                                                                                                : ""
                                                                                    }
                                                                                    onClick={() =>
                                                                                          setCurrentTraveller(
                                                                                                item
                                                                                          )
                                                                                    }
                                                                              >
                                                                                    <div>
                                                                                          {
                                                                                                item
                                                                                          }
                                                                                    </div>
                                                                                    <div>
                                                                                          {
                                                                                                travellerList[
                                                                                                      item
                                                                                                ]
                                                                                                      ?.Seat
                                                                                                      ?.code
                                                                                          }
                                                                                    </div>
                                                                                    <div>
                                                                                          ₹{" "}
                                                                                          {
                                                                                                travellerList[
                                                                                                      item
                                                                                                ]
                                                                                                      ?.Seat
                                                                                                      ?.amount
                                                                                          }
                                                                                    </div>
                                                                              </li>
                                                                        </>
                                                                  );
                                                            })}

                                                            <li>
                                                                  <div>
                                                                        Total
                                                                  </div>
                                                                  <div></div>
                                                                  <div>
                                                                        ₹{" "}
                                                                        {findingAmount()}
                                                                  </div>
                                                            </li>
                                                      </SeatPriceContent>
                                                      <CommonButton
                                                            width="100%"
                                                            title="Apply Seat"
                                                            padding="6px"
                                                            handleClick={
                                                                  handleSeat
                                                            }
                                                            loading={loading}
                                                            type="button"
                                                            button={true}
                                                      />
                                                </SeatPriceContainer>
                                          </Col>
                                          <Col
                                                lg={8}
                                                className="seat-map mx-auto h-100"
                                          >
                                                <FlightSeatContainer className="h-100">
                                                      {!loadingData ? (
                                                            <FlightSeatContent>
                                                                  {Object.keys(
                                                                        seats
                                                                  ).map(
                                                                        (
                                                                              row
                                                                        ) => (
                                                                              <FlightRow
                                                                                    key={
                                                                                          row
                                                                                    }
                                                                              >
                                                                                    {Array.from(
                                                                                          {
                                                                                                length: seatData?.column,
                                                                                          }
                                                                                    ).map(
                                                                                          (
                                                                                                _,
                                                                                                colIndex
                                                                                          ) => {
                                                                                                const seat =
                                                                                                      seats[
                                                                                                            row
                                                                                                      ] &&
                                                                                                      seats[
                                                                                                            row
                                                                                                      ][
                                                                                                            colIndex +
                                                                                                                  1
                                                                                                      ];
                                                                                                return seat.seatNo ===
                                                                                                      "Non" ? (
                                                                                                      <div
                                                                                                            className={`seat border-0 text-dark`}
                                                                                                      >
                                                                                                            {(colIndex ===
                                                                                                                  3 ||
                                                                                                                  colIndex ===
                                                                                                                        7) &&
                                                                                                                  row}
                                                                                                      </div>
                                                                                                ) : seat.isBooked ? (
                                                                                                      <FlightSeat
                                                                                                            key={
                                                                                                                  seat
                                                                                                                        ? seat.seatNo
                                                                                                                        : `${row}${
                                                                                                                                colIndex +
                                                                                                                                1
                                                                                                                          }`
                                                                                                            }
                                                                                                            className={`${
                                                                                                                  seat.isBooked
                                                                                                                        ? "booked"
                                                                                                                        : "available"
                                                                                                            } `}
                                                                                                      ></FlightSeat>
                                                                                                ) : (
                                                                                                      <FlightSeat
                                                                                                            key={
                                                                                                                  seat
                                                                                                                        ? seat.seatNo
                                                                                                                        : `${row}${
                                                                                                                                colIndex +
                                                                                                                                1
                                                                                                                          }`
                                                                                                            }
                                                                                                            onClick={() =>
                                                                                                                  handleSelectSeat(
                                                                                                                        seat.code
                                                                                                                  )
                                                                                                            }
                                                                                                            className={`${
                                                                                                                  checkColor(
                                                                                                                        seat?.amount
                                                                                                                  )
                                                                                                                        ?.status
                                                                                                                        ? checkColor(
                                                                                                                                seat?.amount
                                                                                                                          )
                                                                                                                                ?.color
                                                                                                                        : ""
                                                                                                            } ${
                                                                                                                  checkSeat(
                                                                                                                        seat?.code
                                                                                                                  )
                                                                                                                        ? "active"
                                                                                                                        : ""
                                                                                                            }`}
                                                                                                      >
                                                                                                            {
                                                                                                                  seat.seatNo
                                                                                                            }
                                                                                                      </FlightSeat>
                                                                                                );
                                                                                          }
                                                                                    )}
                                                                              </FlightRow>
                                                                        )
                                                                  )}
                                                            </FlightSeatContent>
                                                      ) : (
                                                            <>
                                                                  <Spinner />
                                                            </>
                                                      )}
                                                </FlightSeatContainer>
                                          </Col>
                                    </Row>
                              </FlightModalBody>
                              <FlightModalFooter>
                                    <FlightPriceList>
                                          <li>
                                                <FlightSeat
                                                      height="20px"
                                                      width="20px"
                                                      className={`bg-selected rounded`}
                                                ></FlightSeat>{" "}
                                                Selected
                                          </li>
                                          <li>
                                                <FlightSeat
                                                      height="20px"
                                                      width="20px"
                                                      className={`booked rounded`}
                                                ></FlightSeat>{" "}
                                                Booked
                                          </li>
                                    </FlightPriceList>
                                    <FlightPriceList className="border-top pt-2 pb-0">
                                          {uniquePrice.map((amount, index) => (
                                                <li key={index}>
                                                      <FlightSeat
                                                            height="20px"
                                                            width="20px"
                                                            className={`bg-${index} rounded`}
                                                      ></FlightSeat>{" "}
                                                      ₹ {amount.toFixed(2)}
                                                </li>
                                          ))}
                                    </FlightPriceList>
                              </FlightModalFooter>
                        </Modal.Body>
                  </FlightModal>
            </>
      );
};

export default SeatMapList;
