import React from "react";
import { SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import {
      BannerCardContainer,
      BannerCardContent,
      BannerCardImage,
      BannerCardInfo,
      BannerCardTitle,
      BannerImage,
      BannerSwiper,
      BannerTitle,
      MobileBannerTitle,
      MobileLoginButton,
} from "./style";
import { FaRegPaperPlane } from "react-icons/fa";

const BannerSlider = () => {
      return (
            <>
                  <BannerSwiper
                        spaceBetween={30}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{
                              delay: 3000,
                        }}
                        pagination={false}
                        navigation={false}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper w-100"
                  >
                        <SwiperSlide>
                              <BannerImage>
                                    <img
                                          src="/images/banners/slider-2.png"
                                          alt=""
                                    />
                              </BannerImage>
                              <BannerTitle>
                                    <div class="custom-shape-divider-top-1733913168">
                                          <svg
                                                data-name="Layer 1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 1200 120"
                                                preserveAspectRatio="none"
                                                fill="#FFF"
                                          >
                                                <path
                                                      d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                                                      class="shape-fill"
                                                ></path>
                                          </svg>
                                    </div>
                                    <div className="customer__title">
                                          Your One Stop B2B Portal For Flight
                                          and Hotel Bookings. We're here to
                                          fulfill all your Customer's Travel
                                          needs!
                                    </div>
                              </BannerTitle>
                        </SwiperSlide>
                        <SwiperSlide>
                              <BannerCardContent>
                                    <BannerCardInfo>
                                          <BannerCardContainer>
                                                <BannerCardTitle>
                                                      HOTELS
                                                </BannerCardTitle>
                                                <BannerCardImage>
                                                      <img
                                                            src="/images/banners/HOTELS.png"
                                                            alt=""
                                                      />
                                                </BannerCardImage>
                                                <p>
                                                      Best World wide hotel
                                                      deals for your customers
                                                </p>
                                          </BannerCardContainer>
                                          <BannerCardContainer>
                                                <BannerCardTitle>
                                                      Flights
                                                </BannerCardTitle>
                                                <BannerCardImage>
                                                      <img
                                                            src="/images/banners/FLIGHTS.png"
                                                            alt=""
                                                      />
                                                </BannerCardImage>
                                                <p>
                                                      Find the best flight deals
                                                      across all fare categories
                                                </p>
                                          </BannerCardContainer>
                                          <BannerCardContainer>
                                                <BannerCardTitle>
                                                      Easy payment
                                                </BannerCardTitle>
                                                <BannerCardImage>
                                                      <img
                                                            src="/images/banners/EASYPAY.png"
                                                            alt=""
                                                      />
                                                </BannerCardImage>
                                                <p>
                                                      Pay directly via gateway
                                                      or upload funds to wallet
                                                      using RTGS/NEFT/IMPS.
                                                </p>
                                          </BannerCardContainer>
                                          <BannerCardContainer>
                                                <BannerCardTitle>
                                                      Mobile Friendly
                                                </BannerCardTitle>
                                                <BannerCardImage>
                                                      <img
                                                            src="/images/banners/MobileFriendly.png"
                                                            alt=""
                                                      />
                                                </BannerCardImage>
                                                <p>
                                                      Do your business with ease
                                                      on the go
                                                </p>
                                          </BannerCardContainer>
                                    </BannerCardInfo>
                              </BannerCardContent>
                        </SwiperSlide>
                  </BannerSwiper>
                  <MobileBannerTitle>
                        <div class="custom-shape-divider-top-1733913168">
                              <svg
                                    data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 1200 120"
                                    preserveAspectRatio="none"
                                    fill="#FFF"
                              >
                                    <path
                                          d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                                          class="shape-fill"
                                    ></path>
                              </svg>
                        </div>
                        <div className="customer__title">
                              <p>
                                    Your One Stop B2B Portal For Flight and
                                    Hotel Bookings. We're here to fulfill all
                                    your Customer's Travel needs!
                              </p>
                              <MobileLoginButton to="/login">
                                    Login <FaRegPaperPlane />
                              </MobileLoginButton>
                        </div>
                  </MobileBannerTitle>
            </>
      );
};

export default BannerSlider;
