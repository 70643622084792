import React, { useEffect, useState } from "react";
import {
      FlightTripAirline,
      FlightTripAirlineTime,
      FlightTripAirlineTitle,
      FlightTripDetailAirline,
      FlightTripDetailCategory,
      FlightTripDetailContainer,
      FlightTripDetailInfo,
      FlightTripDetailTitle,
} from "../FlightTripDetails/style";
import { Spinner } from "react-bootstrap";
import { convertAmount, convertFloat } from "../../../../hooks/CurrentData";
import { tripJackApi } from "../../../../api";

const FlightTripRule = ({ fareId, searchDetail, returnStatus }) => {
      const [fareDetails, setFareDetails] = useState(false);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);

            await tripJackApi
                  .post(
                        `/fms/v1/farerule`,
                        {
                              id: fareId?.id,
                              flowType: "SEARCH",
                        },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        setFareDetails(response.data?.fareRule);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [fareId]);

      // const farePrice
      const splitDetails = (policyString) => {
            const policyList = policyString.split("__nls__");
            return policyList;
      };

      const checkReturn = () => {
            if (returnStatus === true) {
                  return `${searchDetail?.destination}-${searchDetail?.leavingFrom}`;
            } else {
                  return `${searchDetail?.leavingFrom}-${searchDetail?.destination}`;
            }
      };

      return (
            <>
                  <FlightTripDetailContainer>
                        <FlightTripDetailCategory>{`${searchDetail?.leavingFrom} - ${searchDetail?.destination}`}</FlightTripDetailCategory>
                        {!loading ? (
                              <>
                                    <FlightTripDetailInfo>
                                          {fareId?.fd?.ADULT?.rT ? (
                                                <>
                                                      <FlightTripDetailAirline>
                                                            {fareDetails ? (
                                                                  <>
                                                                        <FlightTripAirlineTime width="25%">
                                                                              <div>
                                                                                    Type
                                                                              </div>
                                                                              <span>
                                                                                    Date
                                                                                    Change
                                                                              </span>
                                                                        </FlightTripAirlineTime>
                                                                        <FlightTripAirline width="35%">
                                                                              <FlightTripAirlineTitle>
                                                                                    <div>
                                                                                          Amount
                                                                                    </div>
                                                                                    <span>
                                                                                          ₹{" "}
                                                                                          {fareDetails[
                                                                                                checkReturn()
                                                                                          ]
                                                                                                ?.fr
                                                                                                ?.DATECHANGE
                                                                                                ?.DEFAULT
                                                                                                ?.amount
                                                                                                ? convertAmount(
                                                                                                        convertFloat(
                                                                                                              fareDetails[
                                                                                                                    checkReturn()
                                                                                                              ]
                                                                                                                    ?.fr
                                                                                                                    ?.DATECHANGE
                                                                                                                    ?.DEFAULT
                                                                                                                    ?.amount
                                                                                                        )
                                                                                                  )
                                                                                                : convertAmount(
                                                                                                        convertFloat(
                                                                                                              fareDetails[
                                                                                                                    checkReturn()
                                                                                                              ]
                                                                                                                    ?.fr
                                                                                                                    ?.DATECHANGE
                                                                                                                    ?.BEFORE_DEPARTURE
                                                                                                                    ?.amount
                                                                                                        )
                                                                                                  )}
                                                                                          {
                                                                                                " + "
                                                                                          }{" "}
                                                                                          ₹{" "}
                                                                                          {fareDetails[
                                                                                                checkReturn()
                                                                                          ]
                                                                                                ?.fr
                                                                                                ?.DATECHANGE
                                                                                                ?.DEFAULT
                                                                                                ?.additionalFee
                                                                                                ? convertAmount(
                                                                                                        convertFloat(
                                                                                                              fareDetails[
                                                                                                                    checkReturn()
                                                                                                              ]
                                                                                                                    ?.fr
                                                                                                                    ?.DATECHANGE
                                                                                                                    ?.DEFAULT
                                                                                                                    ?.additionalFee
                                                                                                        )
                                                                                                  )
                                                                                                : convertAmount(
                                                                                                        convertFloat(
                                                                                                              fareDetails[
                                                                                                                    checkReturn()
                                                                                                              ]
                                                                                                                    ?.fr
                                                                                                                    ?.DATECHANGE
                                                                                                                    ?.BEFORE_DEPARTURE
                                                                                                                    ?.additionalFee
                                                                                                        )
                                                                                                  )}
                                                                                    </span>
                                                                              </FlightTripAirlineTitle>
                                                                        </FlightTripAirline>
                                                                        <FlightTripAirlineTime width="40%">
                                                                              <div>
                                                                                    Policy
                                                                              </div>
                                                                              <span>
                                                                                    {fareDetails[
                                                                                          checkReturn()
                                                                                    ]
                                                                                          ?.fr
                                                                                          ?.DATECHANGE
                                                                                          ?.DEFAULT
                                                                                          ?.policyInfo
                                                                                          ? splitDetails(
                                                                                                  fareDetails[
                                                                                                        checkReturn()
                                                                                                  ]
                                                                                                        ?.fr
                                                                                                        ?.DATECHANGE
                                                                                                        ?.DEFAULT
                                                                                                        ?.policyInfo
                                                                                            )?.map(
                                                                                                  (
                                                                                                        policy,
                                                                                                        index
                                                                                                  ) =>
                                                                                                        policy && (
                                                                                                              <li
                                                                                                                    key={
                                                                                                                          index
                                                                                                                    }
                                                                                                              >
                                                                                                                    {
                                                                                                                          policy
                                                                                                                    }
                                                                                                              </li>
                                                                                                        )
                                                                                            )
                                                                                          : fareDetails[
                                                                                                  checkReturn()
                                                                                            ]
                                                                                                  ?.fr
                                                                                                  ?.DATECHANGE
                                                                                                  ?.BEFORE_DEPARTURE
                                                                                                  ?.policyInfo}
                                                                              </span>
                                                                        </FlightTripAirlineTime>
                                                                  </>
                                                            ) : (
                                                                  <>
                                                                        <FlightTripAirlineTime width="100%">
                                                                              <span className="text-danger">
                                                                                    No
                                                                                    Refundable
                                                                              </span>
                                                                        </FlightTripAirlineTime>
                                                                  </>
                                                            )}
                                                      </FlightTripDetailAirline>

                                                      {fareDetails && (
                                                            <FlightTripDetailAirline marginTop="1.5rem">
                                                                  <FlightTripAirlineTime width="25%">
                                                                        <span>
                                                                              CANCELLATION
                                                                        </span>
                                                                  </FlightTripAirlineTime>
                                                                  <FlightTripAirline width="35%">
                                                                        <FlightTripAirlineTitle>
                                                                              <span>
                                                                                    {`₹ ${
                                                                                          fareDetails[
                                                                                                checkReturn()
                                                                                          ]
                                                                                                ?.fr
                                                                                                ?.CANCELLATION
                                                                                                ?.DEFAULT
                                                                                                ?.amount
                                                                                                ? convertAmount(
                                                                                                        convertFloat(
                                                                                                              fareDetails[
                                                                                                                    checkReturn()
                                                                                                              ]
                                                                                                                    ?.fr
                                                                                                                    ?.CANCELLATION
                                                                                                                    ?.DEFAULT
                                                                                                                    ?.amount
                                                                                                        )
                                                                                                  )
                                                                                                : convertAmount(
                                                                                                        convertFloat(
                                                                                                              fareDetails[
                                                                                                                    checkReturn()
                                                                                                              ]
                                                                                                                    ?.fr
                                                                                                                    ?.CANCELLATION
                                                                                                                    ?.BEFORE_DEPARTURE
                                                                                                                    ?.amount
                                                                                                        )
                                                                                                  )
                                                                                    }`}

                                                                                    {` + ₹ ${
                                                                                          fareDetails[
                                                                                                checkReturn()
                                                                                          ]
                                                                                                ?.fr
                                                                                                ?.CANCELLATION
                                                                                                ?.DEFAULT
                                                                                                ?.additionalFee
                                                                                                ? convertAmount(
                                                                                                        convertFloat(
                                                                                                              fareDetails[
                                                                                                                    checkReturn()
                                                                                                              ]
                                                                                                                    ?.fr
                                                                                                                    ?.CANCELLATION
                                                                                                                    ?.DEFAULT
                                                                                                                    ?.additionalFee
                                                                                                        )
                                                                                                  )
                                                                                                : convertAmount(
                                                                                                        convertFloat(
                                                                                                              fareDetails[
                                                                                                                    checkReturn()
                                                                                                              ]
                                                                                                                    ?.fr
                                                                                                                    ?.CANCELLATION
                                                                                                                    ?.BEFORE_DEPARTURE
                                                                                                                    ?.additionalFee
                                                                                                        )
                                                                                                  )
                                                                                    }`}
                                                                              </span>
                                                                        </FlightTripAirlineTitle>
                                                                  </FlightTripAirline>
                                                                  <FlightTripAirlineTime width="40%">
                                                                        <span>
                                                                              {fareDetails[
                                                                                    checkReturn()
                                                                              ]
                                                                                    ?.fr
                                                                                    ?.CANCELLATION
                                                                                    ?.DEFAULT
                                                                                    ?.policyInfo
                                                                                    ? splitDetails(
                                                                                            fareDetails[
                                                                                                  checkReturn()
                                                                                            ]
                                                                                                  ?.fr
                                                                                                  ?.CANCELLATION
                                                                                                  ?.DEFAULT
                                                                                                  ?.policyInfo
                                                                                      )?.map(
                                                                                            (
                                                                                                  policy,
                                                                                                  index
                                                                                            ) =>
                                                                                                  policy && (
                                                                                                        <li
                                                                                                              key={
                                                                                                                    index
                                                                                                              }
                                                                                                        >
                                                                                                              {
                                                                                                                    policy
                                                                                                              }
                                                                                                        </li>
                                                                                                  )
                                                                                      )
                                                                                    : fareDetails[
                                                                                            checkReturn()
                                                                                      ]
                                                                                            ?.fr
                                                                                            ?.CANCELLATION
                                                                                            ?.BEFORE_DEPARTURE
                                                                                            ?.policyInfo}
                                                                        </span>
                                                                  </FlightTripAirlineTime>
                                                            </FlightTripDetailAirline>
                                                      )}
                                                </>
                                          ) : (
                                                <>
                                                      <FlightTripAirlineTime width="100%">
                                                            <span className="text-danger">
                                                                  Non Refundable
                                                            </span>
                                                      </FlightTripAirlineTime>
                                                </>
                                          )}
                                    </FlightTripDetailInfo>
                                    {fareDetails ? (
                                          fareId?.fd?.ADULT?.rT ? (
                                                <FlightTripDetailAirline
                                                      className="mt-2"
                                                      flexDirection="column"
                                                >
                                                      <FlightTripDetailTitle flexDirection="column">
                                                            Provider
                                                            Cancellation /
                                                            Reschedule Fee:
                                                      </FlightTripDetailTitle>
                                                      <div className="text-muted">
                                                            109 for domestic
                                                            (per person)
                                                      </div>
                                                      <div className="text-muted">
                                                            218 for
                                                            international (per
                                                            person)
                                                      </div>
                                                </FlightTripDetailAirline>
                                          ) : (
                                                ""
                                          )
                                    ) : (
                                          ""
                                    )}
                              </>
                        ) : (
                              <>
                                    <FlightTripDetailInfo>
                                          <div className="w-100 text-center mb-3">
                                                <Spinner />
                                          </div>
                                    </FlightTripDetailInfo>
                              </>
                        )}
                  </FlightTripDetailContainer>
            </>
      );
};

export default FlightTripRule;
