import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { format } from "date-fns";
import { DashboardContainer } from "../dashboard/styles";
import HotelTicketLayout from "../hotel_confirmations/HotelTicketLayout";
import { handleHotelCommissionTotal } from "../../hooks/hotelCommission";
import { tripJackApi } from "../../api";

export const convertDate = (isoString) => {
      const date = new Date(isoString);
      return format(date, "dd-MMM-yyyy");
};

const HotelTicket = () => {
      const location = useLocation();
      const searchParams = new URLSearchParams(location.search);

      const markupPrice = parseFloat(searchParams.get("markupPrice"));
      const withPriceStatus =
            searchParams.get("withPriceStatus") === "true" ? true : false;
      const withInfo = searchParams.get("withInfo") === "true" ? true : false;

      const [loading, setLoading] = useState(false);
      const { bookingId } = useParams();
      const [profile, setProfile] = useState(false);

      const [agentOrder, setAgentOrder] = useState([]);
      const [order, setOrder] = useState([]);
      const [tripInfos, setTripInfos] = useState([]);
      const [totalAmount, setTotalAmount] = useState([]);
      const [priceInfos, setPriceInfos] = useState([]);
      const [searchQuery, setSearchQuery] = useState([]);

      const handleTotalPrice = ({ prices, commissionsAmount }) => {
            const totalAmt = handleHotelCommissionTotal({
                  prices: prices,
                  commissionsAmount: commissionsAmount,
            });
            return totalAmt;
      };

      // Load booking data
      const loadData = async () => {
            setLoading(true);
            const response = await axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/flights/${bookingId}`,
                  {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  }
            );

            if (response?.data?.result === "success") {
                  setAgentOrder(response.data.agent);
                  setProfile(response.data.agentDetail);

                  await tripJackApi
                        .post(
                              `/oms/v1/hotel/booking-details`,
                              {
                                    bookingId:
                                          response?.data?.agent?.tj_booking_id,
                                    requirePaxPricing: true,
                              },
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response?.data?.status?.success === true) {
                                    setOrder(response.data.order);
                                    setTripInfos(
                                          response.data.itemInfos?.HOTEL?.hInfo
                                    );
                                    setPriceInfos(
                                          response.data.itemInfos?.HOTEL?.hInfo
                                                ?.ops[0]
                                    );
                                    setSearchQuery(
                                          response.data.itemInfos?.HOTEL?.query
                                    );
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
            setLoading(false);
      };

      // Update booking data
      useEffect(() => {
            loadData();
      }, []);

      useEffect(() => {
            setTotalAmount(
                  handleTotalPrice({
                        prices: priceInfos?.ris,
                        commissionsAmount: agentOrder?.commission,
                  })
            );
      }, [priceInfos, agentOrder]);

      return (
            <>
                  <DashboardContainer
                        backgroundColor="#FFF"
                        className="p-0"
                        flexDirection="column"
                        padding="0px"
                  >
                        {!loading ? (
                              <HotelTicketLayout
                                    tripInfos={tripInfos}
                                    priceInfos={priceInfos}
                                    bookingId={bookingId}
                                    order={order}
                                    searchQuery={searchQuery}
                                    totalAmount={totalAmount}
                                    agentOrder={agentOrder}
                                    markupPrice={markupPrice}
                                    withInfo={withInfo}
                                    profile={profile}
                                    currentPriceStatus={withPriceStatus}
                              />
                        ) : (
                              <div className="text-center">
                                    <Spinner />
                              </div>
                        )}
                  </DashboardContainer>
            </>
      );
};

export default HotelTicket;
