import React, { useEffect, useState } from "react";
import {
      BorderContainer,
      FirstSection,
      Header,
      InfoBlock,
      InvoiceContainer,
      Label,
      LeftSection,
      RightInfoBlock,
      RightSection,
      SecondInfo,
      SecondInfoBlock,
      SecondSection,
      Separator,
      Table,
      Td,
      Th,
      Title,
      Value,
} from "./style";
import { DashboardContainer } from "../layouts/style";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const InvoiceTicket = () => {
      const { bookingId } = useParams();
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem("token"));
      const [officeDetail, setOfficeDetail] = useState([]);
      const [orderDetail, setOrderDetail] = useState([]);
      const [agentDetail, setAgentDetail] = useState([]);
      const [invoice, setInvoice] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/general_settings`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setOfficeDetail(response.data.general);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            const response = await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/invoices/${bookingId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .catch((error) => {
                        toast.error(error.message);
                  });

            if (response?.data?.result === "success") {
                  setOrderDetail(response.data.agent);
                  setAgentDetail(response.data.agentDetail);
                  setInvoice(response.data.invoice);
            }
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <DashboardContainer>
                        <InvoiceContainer>
                              <BorderContainer>
                                    <Header>Invoice (Original)</Header>
                                    <FirstSection>
                                          <LeftSection>
                                                <Title>
                                                      {
                                                            officeDetail?.office_name
                                                      }
                                                </Title>
                                                <InfoBlock>
                                                      {
                                                            officeDetail?.office_address
                                                      }
                                                </InfoBlock>
                                                <InfoBlock>
                                                      Phone:{" "}
                                                      {
                                                            officeDetail?.office_phone
                                                      }
                                                </InfoBlock>
                                                <InfoBlock>
                                                      Email:{" "}
                                                      {
                                                            officeDetail?.office_email
                                                      }
                                                </InfoBlock>
                                                <InfoBlock>
                                                      Web:{" "}
                                                      {
                                                            officeDetail?.office_website
                                                      }
                                                </InfoBlock>
                                          </LeftSection>

                                          <RightSection>
                                                <RightInfoBlock>
                                                      <Label>Invoice No.</Label>
                                                      <Separator>:</Separator>
                                                      <Value>
                                                            {
                                                                  invoice?.invoice_no
                                                            }
                                                      </Value>
                                                </RightInfoBlock>
                                                <RightInfoBlock>
                                                      <Label>Date </Label>
                                                      <Separator>:</Separator>
                                                      <Value>
                                                            04/02/2025 12:56:13
                                                      </Value>
                                                </RightInfoBlock>
                                                <RightInfoBlock>
                                                      <Label>GST Number</Label>
                                                      <Separator>:</Separator>
                                                      <Value>
                                                            {
                                                                  officeDetail?.office_gst_number
                                                            }
                                                      </Value>
                                                </RightInfoBlock>
                                                <RightInfoBlock>
                                                      <Label>SAC Code</Label>
                                                      <Separator>:</Separator>
                                                      <Value> 99855</Value>
                                                </RightInfoBlock>
                                                <RightInfoBlock>
                                                      <Label>
                                                            Reference Number
                                                      </Label>
                                                      <Separator>:</Separator>
                                                      <Value>FBB8FRTU</Value>
                                                </RightInfoBlock>
                                          </RightSection>
                                    </FirstSection>

                                    <SecondSection>
                                          <Title>Billed To</Title>
                                          <SecondInfo>
                                                <Title>
                                                      {
                                                            agentDetail?.company
                                                                  ?.company_name
                                                      }{" "}
                                                      - ({" "}
                                                      {
                                                            agentDetail
                                                                  ?.personal
                                                                  ?.user_name
                                                      }
                                                      -
                                                      {
                                                            agentDetail
                                                                  ?.personal
                                                                  ?.name
                                                      }
                                                      )
                                                </Title>
                                                <SecondInfoBlock>
                                                      <p>Address</p>
                                                      <strong>:</strong>
                                                      <span>
                                                            {`${agentDetail?.company?.office_address}, ${agentDetail?.company?.state} - ${agentDetail?.pincode}`}
                                                      </span>
                                                </SecondInfoBlock>
                                                <SecondInfoBlock>
                                                      <p>Email </p>
                                                      <strong>:</strong>
                                                      <span>
                                                            {
                                                                  agentDetail
                                                                        ?.personal
                                                                        ?.email
                                                            }
                                                      </span>
                                                </SecondInfoBlock>
                                                <SecondInfoBlock>
                                                      <p>PAN Number </p>
                                                      <strong>:</strong>
                                                      <span>
                                                            {
                                                                  agentDetail
                                                                        ?.company
                                                                        ?.pan_no
                                                            }
                                                      </span>
                                                </SecondInfoBlock>
                                          </SecondInfo>
                                    </SecondSection>

                                    <SecondSection>
                                          <Table>
                                                <thead>
                                                      <tr>
                                                            <Th>Description</Th>
                                                            <Th>
                                                                  Supplier Ref
                                                                  No.
                                                            </Th>
                                                            <Th>Basic</Th>
                                                            <Th>Taxes</Th>
                                                            <Th>SSR</Th>
                                                            <Th>
                                                                  Ticket Value{" "}
                                                                  <br /> (INR)
                                                            </Th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {invoice?.travellers?.map(
                                                            (item, index) => (
                                                                  <tr>
                                                                        <Td>
                                                                              <p>
                                                                                    Onward
                                                                                    Journey:
                                                                                    JAI-DMK
                                                                                    |
                                                                                    FD-131
                                                                                    |
                                                                                    11/02/2025
                                                                                    23:10:00{" "}
                                                                                    <br />{" "}
                                                                                    12/02/2025
                                                                                    04:30:00
                                                                              </p>
                                                                              <p>
                                                                                    Return
                                                                                    Journey:
                                                                                    DMK-JAI
                                                                                    |
                                                                                    FD-130
                                                                                    |
                                                                                    19/02/2025
                                                                                    19:10:00{" "}
                                                                                    <br />{" "}
                                                                                    19/02/2025
                                                                                    22:10:00
                                                                              </p>
                                                                              <h3>
                                                                                    Mr
                                                                                    VIRENDRA
                                                                                    PRATAP
                                                                                    SINGH
                                                                                    CHARAN
                                                                                    |
                                                                                    GFPT3ERTU1
                                                                              </h3>
                                                                        </Td>
                                                                        <Td>
                                                                              GFPT3E
                                                                        </Td>
                                                                        <Td>
                                                                              8801.00
                                                                        </Td>
                                                                        <Td>
                                                                              10967.00
                                                                        </Td>
                                                                        <Td>
                                                                              4628.00
                                                                        </Td>
                                                                        <Td>
                                                                              24396.00
                                                                        </Td>
                                                                  </tr>
                                                            )
                                                      )}
                                                      <tr>
                                                            <Td colSpan="5">
                                                                  <div className="text-align">
                                                                        (+)
                                                                        Transaction
                                                                        Fee
                                                                  </div>
                                                            </Td>
                                                            <Td>
                                                                  {" "}
                                                                  <div className="text-align">
                                                                        100.00
                                                                  </div>
                                                            </Td>
                                                      </tr>
                                                      <tr>
                                                            <Td colSpan="5">
                                                                  <div className="text-align">
                                                                        (-)
                                                                        Commission{" "}
                                                                  </div>
                                                            </Td>
                                                            <Td>
                                                                  {" "}
                                                                  <div className="text-align">
                                                                        0.00
                                                                  </div>
                                                            </Td>
                                                      </tr>
                                                      <tr>
                                                            <Td colSpan="5">
                                                                  <div className="text-align">
                                                                        (+) TDS
                                                                        Deducted{" "}
                                                                  </div>
                                                            </Td>
                                                            <Td>
                                                                  <div className="text-align">
                                                                        0.00
                                                                  </div>
                                                            </Td>
                                                      </tr>
                                                      <tr>
                                                            <Td colSpan="5">
                                                                  {" "}
                                                                  <div className="text-align">
                                                                        (+) GST{" "}
                                                                  </div>
                                                            </Td>
                                                            <Td>
                                                                  <div className="text-align">
                                                                        18.00
                                                                  </div>
                                                            </Td>
                                                      </tr>
                                                      <tr>
                                                            <Td colSpan="5">
                                                                  {" "}
                                                                  <div className="text-align">
                                                                        (+) IGST
                                                                  </div>
                                                            </Td>
                                                            <Td>
                                                                  <div className="text-align">
                                                                        0.00
                                                                  </div>
                                                            </Td>
                                                      </tr>
                                                      <tr>
                                                            <Td colSpan="5">
                                                                  {" "}
                                                                  <div className="text-align">
                                                                        <strong>
                                                                              Total
                                                                        </strong>
                                                                  </div>
                                                            </Td>
                                                            <Td>
                                                                  <strong>
                                                                        <div className="text-align">
                                                                              24514.00
                                                                        </div>
                                                                  </strong>
                                                            </Td>
                                                      </tr>
                                                </tbody>
                                          </Table>
                                    </SecondSection>
                              </BorderContainer>
                              <p className="note d-inline-block me-2">Note:</p>
                              <span className="content">
                                    This is a computer generated invoice.
                                    Signature not required.
                              </span>
                        </InvoiceContainer>
                  </DashboardContainer>
            </>
      );
};

export default InvoiceTicket;
