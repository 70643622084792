import axios from "axios";

const token = JSON.parse(localStorage.getItem("token"));

export const tripJackApi = axios.create({
      baseURL: process.env.REACT_APP_Flight_Api_Website,
      headers: {
            apikey: process.env.REACT_APP_Flight_Api_Key,
            "X-Forwarded-For": "27.34.67.64",
      },
});

export const airCasaApi = axios.create({
      baseURL: `${process.env.REACT_APP_SECRET_KEY}/api`,
      headers: {
            Authorization: `Bearer ${token}`,
      },
});

export const publicApi = axios.create({
      baseURL: `${process.env.REACT_APP_SECRET_KEY}/api`,
      headers: {
            apikey: process.env.REACT_APP_API_KEY,
      },
});
