import React, { useState } from "react";
import {
      CloseButton,
      Col,
      Form,
      FormLabel,
      ModalBody,
      ModalHeader,
      Row,
} from "react-bootstrap";
import { FlightModal } from "../FlightConfirmModal/style";
import Select from "react-select";

import RescheduleFlightForm from "./RescheduleFlightForm";
import CancelRequestForm from "./CancelRequestForm";
import SsrRequestForm from "./SsrRequestForm";

const options = [
      {
            label: "Reschedule Flight",
            value: "Reschedule Flight",
      },
      {
            label: "Cancel Flight",
            value: "Cancel Flight",
      },
      {
            label: "Add SSR",
            value: "Add SSR",
      },
];

const RaiseRequestModal = ({
      bookingId,
      show,
      handleClose,
      travellers,
      uniqueRoundTrips,
      setShowMessage,
      agentOrder,
      travellerInfos,
      tripInfos,
}) => {
      
      const [requestType, setRequestType] = useState("");

      return (
            <>
                  <FlightModal
                        backdrop="static"
                        show={show}
                        size="lg"
                        top="18px"
                  >
                        <ModalBody>
                              <CloseButton onClick={handleClose} />
                              <Row className="g-3">
                                    <Col lg={10}>
                                          <FormLabel>Request Type</FormLabel>
                                          <Select
                                                options={options}
                                                value={requestType}
                                                onChange={setRequestType}
                                          />
                                    </Col>
                                    {requestType.value ===
                                          "Reschedule Flight" && (
                                          <RescheduleFlightForm
                                                travellers={travellers}
                                                uniqueRoundTrips={
                                                      uniqueRoundTrips
                                                }
                                                mainModalClose={handleClose}
                                                bookingId={bookingId}
                                                requestType={requestType}
                                                setShowMessage={setShowMessage}
                                          />
                                    )}
                                    {requestType.value === "Cancel Flight" && (
                                          <CancelRequestForm
                                                bookingId={bookingId}
                                                travellerInfos={travellerInfos}
                                                mainModalClose={handleClose}
                                                setShowMessage={setShowMessage}
                                                requestType={requestType}
                                                agentOrder={agentOrder}
                                                tripInfos={tripInfos}
                                          />
                                    )}
                                    {requestType.value === "Add SSR" && (
                                          <SsrRequestForm />
                                    )}
                              </Row>
                        </ModalBody>
                  </FlightModal>
            </>
      );
};

export default RaiseRequestModal;
