import React, { useContext } from "react";
import PriceSummaryContent from "../PriceSummaryContent";
import { convertAmount, convertFloat } from "../../../../../hooks/CurrentData";
import UserContext from "../../../../../context/UserContext";

const PriceSummaryDetails = ({
      priceInfo,
      totalServicesAmount,
      mealAmount,
      baggageAmount,
      seatDetailAmount,
      otherServiceAmount,
      border,
}) => {
      const userCtx = useContext(UserContext);

      return (
            <>
                  <ul className="price__list list-inline mb-0 border-bottom">
                        <li className="price__item d-flex align-items-center">
                              <div className="price__item--title w-75">
                                    Base Fare
                              </div>
                              <div className="price__item--amount">
                                    ₹{" "}
                                    {convertAmount(
                                          convertFloat(priceInfo?.fC?.BF)
                                    )}
                              </div>
                        </li>
                  </ul>
                  <PriceSummaryContent
                        title="Taxes & Fees"
                        border={border}
                        totalServicesAmount={convertAmount(
                              convertFloat(
                                    priceInfo?.fC?.TAF +
                                          userCtx?.profile?.flight_fee
                              )
                        )}
                  >
                        <ul className="price__list list-inline mb-0">
                              {priceInfo?.afC?.TAF?.AGST ? (
                                    <li className="price__item  d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                Airline GST
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            priceInfo?.afC?.TAF
                                                                  ?.AGST
                                                      )
                                                )}
                                          </div>
                                    </li>
                              ) : (
                                    ""
                              )}
                              {priceInfo?.afC?.TAF?.MF ? (
                                    <li className="price__item  d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                Management Fee
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            priceInfo?.afC?.TAF
                                                                  ?.MF
                                                      )
                                                )}
                                          </div>
                                    </li>
                              ) : (
                                    ""
                              )}
                              {priceInfo?.afC?.TAF?.MFT ? (
                                    <li className="price__item  d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                Management Fee Tax
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            priceInfo?.afC?.TAF
                                                                  ?.MFT
                                                      )
                                                )}
                                          </div>
                                    </li>
                              ) : (
                                    ""
                              )}
                              {priceInfo?.afC?.TAF?.YQ ? (
                                    <li className="price__item  d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                Fuel Surcharge
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            priceInfo?.afC?.TAF
                                                                  ?.YQ
                                                      )
                                                )}
                                          </div>
                                    </li>
                              ) : (
                                    ""
                              )}
                              {priceInfo?.afC?.TAF?.OT ? (
                                    <li className="price__item  d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                Other Taxes
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            priceInfo?.afC?.TAF
                                                                  ?.OT
                                                      )
                                                )}
                                          </div>
                                    </li>
                              ) : (
                                    ""
                              )}
                              {priceInfo?.afC?.TAF?.YR ? (
                                    <li className="price__item  d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                YR
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            priceInfo?.afC?.TAF
                                                                  ?.YR
                                                      )
                                                )}
                                          </div>
                                    </li>
                              ) : (
                                    ""
                              )}
                              {userCtx.profile.flight_fee && (
                                    <li className="price__item  d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                Service Charges
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            userCtx?.profile
                                                                  ?.flight_fee ||
                                                                  0
                                                      )
                                                )}
                                          </div>
                                    </li>
                              )}
                        </ul>
                  </PriceSummaryContent>
                  <PriceSummaryContent
                        title="Add Ons"
                        totalServicesAmount={convertAmount(
                              convertFloat(totalServicesAmount)
                        )}
                  >
                        <ul className="price__list list-inline mb-0">
                              {mealAmount >= 0 ? (
                                    <li className="price__item">
                                          <div className="price__item--title w-75">
                                                Meal
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(mealAmount)
                                                )}
                                          </div>
                                    </li>
                              ) : (
                                    ""
                              )}
                              {baggageAmount >= 0 ? (
                                    <li className="price__item">
                                          <div className="price__item--title w-75">
                                                Baggage
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            baggageAmount
                                                      )
                                                )}
                                          </div>
                                    </li>
                              ) : (
                                    ""
                              )}
                              {otherServiceAmount >= 0 ? (
                                    <li className="price__item">
                                          <div className="price__item--title w-75">
                                                Other Services
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            otherServiceAmount
                                                      )
                                                )}
                                          </div>
                                    </li>
                              ) : (
                                    ""
                              )}
                              {seatDetailAmount >= 0 ? (
                                    <li className="price__item">
                                          <div className="price__item--title w-75">
                                                Seat
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            seatDetailAmount
                                                      )
                                                )}
                                          </div>
                                    </li>
                              ) : (
                                    ""
                              )}
                        </ul>
                  </PriceSummaryContent>
            </>
      );
};

export default PriceSummaryDetails;
