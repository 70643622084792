import React, { useState } from "react";
import { PriceSummary } from "../../checkout/styles";
import {
      OtherServiceContainer,
      ServiceButton,
} from "../FlightConfirmPayment/style";
import { LuTickets } from "react-icons/lu";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { MdFlight } from "react-icons/md";
import RaiseRequestModal from "./RaiseRequestModal";
import { Spinner } from "react-bootstrap";
import CommonButton from "../../../components/Common/CommonButton";
import axios from "axios";
import { airCasaApi } from "../../../api";
import toast from "react-hot-toast";

const RaiseRequest = ({
      bookingId,
      travellerInfos,
      setShowMessage,
      agentOrder,
      tripInfos,
}) => {
      const [show, setShow] = useState(false);
      const [loading, setLoading] = useState(false);

      const handleShow = () => {
            setLoading(true);

            setTimeout(() => {
                  setShow(true);
                  setLoading(false);
            }, 2000);
      };

      const handleClose = () => {
            setShow(false);
      };

      const groupedByDestination = travellerInfos.reduce((result, traveler) => {
            const destinations = Object.keys(traveler.pnrDetails);

            destinations.forEach((destination) => {
                  if (!result[destination]) {
                        result[destination] = [];
                  }
                  // Add `checked: false` to the traveler object when pushing
                  result[destination].push({ ...traveler, checked: false });
            });

            return result;
      }, {});

      const uniqueDestinations = Array.from(
            new Set(
                  travellerInfos.flatMap((traveler) =>
                        Object.keys(traveler.pnrDetails)
                  )
            )
      ).map((destination, index) => ({
            destination,
            checked: index === 0,
            flightNo: "",
            date: "",
            title: index === 0 ? "Departure" : "Return",
            index: index,
      }));

      const [generating, setGenerating] = useState(false);

      const generateInvoice = async () => {
            console.log(tripInfos);
            setGenerating(true);
            await airCasaApi
                  .post(`/agent/invoices/${bookingId}`, {
                        travellers: tripInfos,
                  })
                  .then((response) => {
                        toast.success(response.data.message);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setGenerating(false);
      };

      return (
            <>
                  <PriceSummary>
                        <div className="price__box py-3 mb-4">
                              <div className="price__heading">
                                    <div className="price__title w-75">
                                          Raise Request
                                    </div>
                              </div>
                              <OtherServiceContainer>
                                    <ul>
                                          <li>
                                                <LuTickets />
                                                <span>Cancel Flight</span>
                                          </li>
                                          <li>
                                                <RiCalendarScheduleLine />
                                                <span>Reschedule Flight</span>
                                          </li>
                                          <li>
                                                <MdFlight />
                                                <span>Add SSR</span>
                                          </li>
                                    </ul>
                                    <div className="d-flex align-items-center justify-content-center">
                                          <ServiceButton onClick={handleShow}>
                                                RAISE REQUEST{" "}
                                                {loading ? <Spinner /> : ""}
                                          </ServiceButton>
                                          <CommonButton
                                                padding="6px"
                                                type="button"
                                                button={true}
                                                loading={generating}
                                                classes="ms-3"
                                                handleClick={generateInvoice}
                                                title="Generate Invoice"
                                          />
                                    </div>
                              </OtherServiceContainer>
                        </div>
                  </PriceSummary>
                  <RaiseRequestModal
                        bookingId={bookingId}
                        agentOrder={agentOrder}
                        show={show}
                        handleClose={handleClose}
                        travellerInfos={travellerInfos}
                        travellers={groupedByDestination}
                        uniqueRoundTrips={uniqueDestinations}
                        setShowMessage={setShowMessage}
                        tripInfos={tripInfos}
                  />
            </>
      );
};

export default RaiseRequest;
